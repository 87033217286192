import {
    IonContent, IonTitle, IonPage, IonToolbar, IonHeader,
    IonGrid, IonButtons, IonBackButton, IonButton
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { Player } from '@lottiefiles/react-lottie-player';
import successPayment from "../../../assets/animations/successGreenTick.json";
import loadingShapes from "../../../assets/animations/loadingShapes.json";
import { isPlatform } from '@ionic/core';
import "./PaymentCompleted.css";

type Props = {
    history: any,

    isPaymentSuccessful: boolean,
};

type State = {
    sessionId: string | null,
    paymentSuccess: boolean,
};

class PaymentCompleted extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            sessionId: null,
            paymentSuccess: false,
        }
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/" />
                        </IonButtons>
                        <IonTitle>
                            {this.props.isPaymentSuccessful ? "Grazie" : ""}
                        </IonTitle> {/* TO BE LOCALIZED */}
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <div className="paymentCompletedMainGrid">
                        {
                            (this.props.isPaymentSuccessful ) &&
                            <div className='paymentCompletedSuccessContainer'>
                            <div className="paymentCompletedSuccessIcon">
                                <Player
                                    autoplay
                                    keepLastFrame
                                    src={successPayment}
                                />

                            </div>
                            <p className="paymentCompletedSuccessText">
                                    Pagamento completato!<br />
                                    Grazie per aver acquistato Reasy. {/* TO BE LOCALIZED */}
                                </p>

                                <div className="paymentCompletedSuccessButtonsDiv">
                                    <IonButton
                                        onClick={() => {
                                            this.props.history.replace(`/`);
                                        }}
                                    >
                                        Inizia subito {/* TO BE LOCALIZED */}
                                    </IonButton>

                                    <IonButton
                                        className="paymentCompletedSuccessDiscoverButton"
                                        fill='outline'
                                        onClick={() => {
                                            window.open("https://reasy.it/tutorial", "_blank");
                                        }}
                                    >
                                        Scopri cosa puoi fare con Reasy {/* TO BE LOCALIZED */}
                                    </IonButton>
                                </div>
                         </div>   
                        }


                        {
                            !isPlatform("capacitor") && !isPlatform("cordova") &&
                            !this.props.isPaymentSuccessful &&
                            <div className="paymentProcessingProcessingDiv">
                                <div>
                                    <Player
                                        className='loadingBlob'
                                        autoplay
                                        loop
                                        src={loadingShapes}
                                    />
                                </div>
                                <p className="paymentProcessingProcessingParagraph">
                                    Stiamo processando il pagamento
                                </p>
                            </div>
                        }

                    </div>
                </IonContent>
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        isPaymentSuccessful: state.purchases.paymentSuccess,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentCompleted);
import { get, push, ref, remove, update } from "firebase/database";
import { database } from "../../../firebaseConfig";
import axios from "axios";
import { add, body, language } from "ionicons/icons";
import { User } from "firebase/auth";
import { UserData } from "./reducers";
import { backendApi } from "../../../common/apiService";
export interface Highlight {
	uuid: string;
	target: string;
	color: string;
}
export interface Coloring {
	id: string;
	text_pattern: string;
	color: string;
	created_at: number;
	user_id: string;
}
export interface SettingsStore {
	highlightings: Highlight[];
	document: DocumentFontSettings;
}

export interface DocumentFontSettings {
	font: string;
	fontSize: number;
	lineLeading: number;
}
export interface UserSettings {
	id?: string;
	font_name?: string;
	font_size?: number;
	ex_font_size?: number;
	line_height?: number;
	ex_line_height?: number;
	speech_rate?: number;
	speech_gender?: string;
	theme?: string;
}
export interface DocumentPreferredPalette {
	preferredPalette: string;
}
export interface DocumentSampleColors {
	sampleColors: string[];
}

export const settingsServices = {
	getHighlightings,
	addHighlighting,
	deleteHighlighting,
	setDefaultHightlightingActive,
	getHighlightingsActive,
	toggleHighlightings,

	getDocumentViewingSettings,
	editFont,
	editFontSize,
	editLineLeading,

	fromBackendFontNameToCssFontFamily,

	getPreferredPalette,
	getColorSamples,
	getUserTutorialChoice,
	getUserTutorialDocumentChoice,
	setUserTutorialDocumentChoice,

	//NEW BACKEND
	getColourings,
	addTextColouring,
	updateTextColouring,
	deleteTextColouring,

	setFontSize,
	setLineHeight,
	setFontType,
	getUserSettings,
	setPreferredTheme,
	getPreferredTheme,

	setTtsSpeed,
	setVoiceType,
	getPrimaryLanguage,
	setPrimaryLanguage
};



function getHighlightings(userUuid: string) {
	return new Promise<Highlight[]>((resolve, reject) => {
		get(ref(database, `a/${userUuid}/h`))
			.then((snapshot) => {
				const highlightingsArray: Highlight[] = [];
				if (snapshot.exists()) {
					const highlightingKeys = Object.keys(snapshot.val());
					for (let i = 0; i < highlightingKeys.length; i++) {
						if (highlightingKeys[i] === "a") {
							break;
						}
						highlightingsArray.push({
							uuid: highlightingKeys[i],
							color: snapshot.val()[highlightingKeys[i]].c as string,
							target: snapshot.val()[highlightingKeys[i]].t as string,
						});
					}
				}
				resolve(highlightingsArray);
			})
			.catch((err) => {
				console.error("[Settings services getHighlightings] error:", err);
				reject(err);
			});
	});
}

function addHighlighting(userUuid: string, target: string, color: string) {
	return new Promise<Highlight>((resolve, reject) => {
		push(ref(database, `a/${userUuid}/h`), {
			c: color,
			t: target,
		})
			.then((dbRef) => {
				if (dbRef.key) {
					resolve({
						uuid: dbRef.key,
						color: color,
						target: target.trim(),
					});
				} else {
					reject("Db ref key is null" + dbRef);
				}
			})
			.catch((err) => {
				console.error("[Settings services] error pushing highlighting:", err);
				reject(err);
			});
	});
}

function setDefaultHightlightingActive(userUuid: string) {
	return new Promise<boolean>((resolve, reject) => {
		update(ref(database, `a/${userUuid}/h`), {
			a: true,
		})
			.then(() => {
				resolve(true);
			})
			.catch((err) => {
				console.error("[Settings services] error setting default hightlighting:", err);
				reject(err);
			});
	});
}

function getHighlightingsActive(userUuid: string) {
	return new Promise<boolean>((resolve, reject) => {
		get(ref(database, `a/${userUuid}/h`))
			.then((snapshot) => {
				if (snapshot.exists()) {
					if (snapshot.val().a !== undefined) {
						resolve(snapshot.val().a);
					} else
						setDefaultHightlightingActive(userUuid).then((result) => {
							// console.log('Set default to ', result);
							resolve(result);
						});
				}
				// resolve(highlightingsArray);
			})
			.catch((err) => {
				console.error("[Settings services getHighlightings] error:", err);
				reject(err);
			});
	});
}
function toggleHighlightings(userUuid: string, active: boolean) {
	return new Promise<any>((resolve, reject) => {
		update(ref(database, `a/${userUuid}/h`), {
			a: active,
		})
			.then(() => {
				getDocumentViewingSettings(userUuid)
					.then((documentFontSettings) => {
						resolve(documentFontSettings);
					})
					.catch((err) => {
						reject(err);
					});
			})
			.catch((err) => {
				console.error("[getDocumentViewingSettings] error:", err);
				reject(err);
			});
	});
}
function deleteHighlighting(userUuid: string, highlightingUuid: string) {
	return new Promise((resolve, reject) => {
		remove(ref(database, `a/${userUuid}/h/${highlightingUuid}`))
			.then((response) => {
				resolve(response);
			})
			.catch((err) => {
				console.error("[Setting services] deleteHighlighting error:", err);
				reject(err);
			});
	});
}

function getDocumentViewingSettings(userUuid: string) {
	return new Promise<DocumentFontSettings>((resolve, reject) => {
		get(ref(database, `a/${userUuid}/f`))
			.then((snapshot) => {
				if (snapshot.exists()) {
					resolve({
						font: snapshot.val().f ? snapshot.val().f : "open_dyslexic",
						lineLeading: snapshot.val().l ? snapshot.val().l : 0,
						fontSize: snapshot.val().s ? snapshot.val().s : 30,
					});
				} else {
					resolve({
						font: "arial",
						lineLeading: 50,
						fontSize: 30,
					});
				}
				//console.log('[getDocumentViewingSettings] response:', response.data); // for debugging
			})
			.catch((err) => {
				console.error("[getDocumentViewingSettings] error:", err);
				reject(err);
			});
	});
}

function editFontSize(userUuid: string, fontSize: number) {
	return new Promise<DocumentFontSettings>((resolve, reject) => {
		update(ref(database, `a/${userUuid}/f`), {
			s: fontSize,
		})
			.then(() => {
				getDocumentViewingSettings(userUuid)
					.then((documentFontSettings) => {
						resolve(documentFontSettings);
					})
					.catch((err) => {
						reject(err);
					});
			})
			.catch((err) => {
				console.error("[getDocumentViewingSettings] error:", err);
				reject(err);
			});
	});
}

function editLineLeading(userUuid: string, lineLeading: number) {
	return new Promise<DocumentFontSettings>((resolve, reject) => {
		update(ref(database, `a/${userUuid}/f`), {
			l: lineLeading,
		})
			.then(() => {
				getDocumentViewingSettings(userUuid)
					.then((documentFontSettings) => {
						resolve(documentFontSettings);
					})
					.catch((err) => {
						reject(err);
					});
			})
			.catch((err) => {
				console.error("[getDocumentViewingSettings] error:", err);
				reject(err);
			});
	});
}

function editFont(userUuid: string, font: "arial" | "tahoma" | "serif" | "sans_serif" | "verdana" | "open_dyslexic") {
	return new Promise<DocumentFontSettings>((resolve, reject) => {
		update(ref(database, `a/${userUuid}/f`), {
			f: font,
		})
			.then(() => {
				getDocumentViewingSettings(userUuid)
					.then((documentFontSettings) => {
						resolve(documentFontSettings);
					})
					.catch((err) => {
						reject(err);
					});
			})
			.catch((err) => {
				console.error("[getDocumentViewingSettings] error:", err);
				reject(err);
			});
	});
}

function getPreferredPalette(userUuid: string) {
	return new Promise<DocumentPreferredPalette>((resolve, reject) => {
		get(ref(database, `u/${userUuid}`))
			.then((snapshot) => {
				if (snapshot.exists()) {
					resolve({
						preferredPalette: snapshot.val().t ? snapshot.val().t : "default",
					});
				} else {
					resolve({
						preferredPalette: "default",
					});
				}
				//console.log('[getDocumentViewingSettings] response:', response.data); // for debugging
			})
			.catch((err) => {
				console.error("[getDocumentViewingSettings] error:", err);
				reject(err);
			});
	});
}

function getColorSamples(userUuid: string) {
	return new Promise<DocumentSampleColors>((resolve, reject) => {
		get(ref(database, `u/${userUuid}`))
			.then((snapshot) => {
				if (snapshot.exists()) {
					resolve({
						sampleColors: snapshot.val().cp ? snapshot.val().cp : [],
					});
				} else {
					resolve({
						sampleColors: [],
					});
				}
				//console.log('[getDocumentViewingSettings] response:', response.data); // for debugging
			})
			.catch((err) => {
				console.error("[getDocumentViewingSettings] error:", err);
				reject(err);
			});
	});
}
function getUserTutorialChoice(userUuid: string, page: string) {
	return new Promise<boolean>((resolve, reject) => {
		get(ref(database, `u/${userUuid}/uc/t`))
			.then((snapshot) => {
				if (snapshot.exists()) {
					if (snapshot.val().st !== undefined && snapshot.val().st == false) {
						resolve(snapshot.val().st);
					} else if (snapshot.val()[page] !== undefined) {
						resolve(snapshot.val()[page]);
					} else {
						resolve(true);
					}
				} else {
					resolve(true);
				}

				//console.log('[getDocumentViewingSettings] response:', response.data); // for debugging
			})
			.catch((err) => {
				console.error("[getDocumentViewingSettings] error:", err);
				reject(err);
			});
	});
}

function getUserTutorialDocumentChoice(userUuid: string) {
	return new Promise<boolean>((resolve, reject) => {
		get(ref(database, `u/${userUuid}/uc`))
			.then((snapshot) => {
				if (snapshot.exists()) {
					if (snapshot.val() !== null) {
						resolve(snapshot.val().dt);
					} else {
						resolve(false);
					}
				} else {
					resolve(false);
				}

				//console.log('[getDocumentViewingSettings] response:', response.data); // for debugging
			})
			.catch((err) => {
				console.error("[getDocumentViewingSettings] error:", err);
				reject(err);
			});
	});
}
function setUserTutorialDocumentChoice(userUuid: string, state: boolean) {
	update(ref(database, `u/${userUuid}/uc`), {
		dt: state,
	}).catch((err) => {
		console.error("[Profile] error updating user choice on tutorial:", err);
	});
}

export function fromBackendFontNameToCssFontFamily(fontName: string) {
	switch (fontName) {
		case "arial":
			return "Arial, Helvetica, sans-serif";

		case "georgia":
			return `Georgia, Times, Times New Roman, serif`;

		case "tahoma":
			return `Tahoma, Verdana, Segoe, sans-serif`;

		case "verdana":
			return `Verdana, Geneva, sans-serif`;

		case "easy_reading":
			return `OpenDyslexic, Arial, sans-serif`; /* SINCE CONTRACT IS EXPIRED, EXISTING FONTS SELECTED AS EASYREADING WILL FALLBACK TO THIS */

		case "open_dyslexic":
			return `OpenDyslexic, Arial, sans-serif`;

		default:
			return "Arial, Helvetica, sans-serif";
	}
}
// ------------------------- NEW BACKEND -------------------------
function getColourings(user_id: string, token: string) {
	return new Promise<Coloring[]>((resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		let colourings: Coloring[];
		backendApi
			.get(`/users/${user_id}/text-colorings`, config)
			.then((response) => {
				if (response.status === 200) {
					console.log("[getColourings]Fetch successful", response);
					colourings = response.data.text_colorings;
					resolve(colourings);
				} else {
					reject(new Error(`[getColourings]Request failed with status ${response.status}`));
				}
			})
			.catch((error) => {
				console.error(`[getColourings]Error deleting conceptual maps`, error);
				reject(error);
			});
	});
}

function addTextColouring(user_id: string, letter: string, color: string, token: string) {
	return new Promise<Coloring>((resolve, reject) => {
		// const config = {
		// 	data:
		// };
		let colouring: Coloring;
		backendApi
			.post(
				`/users/${user_id}/text-colorings`,
				{
					text_pattern: letter.trim(),
					color: color,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((response) => {
				if (response.status === 201) {
					console.log("[addTextColouring]added successfully", response);
					colouring = response.data.text_coloring;
					resolve(colouring);
				} else {
					reject(new Error(`[addTextColouring]Request failed with status ${response.status}`));
				}
			})
			.catch((error) => {
				console.error(`[addTextColouring]Error fetching the colorings`, error);
				reject(error);
			});
	});
}

function updateTextColouring(user_id: string, text_coloring_id: string, letter: string, color: string, token: string) {
	return new Promise<Coloring>((resolve, reject) => {
		// const config = {
		// 	data:
		// };
		let colouring: Coloring;
		backendApi
			.patch(
				`/users/${user_id}/text-colorings/${text_coloring_id}`,
				{
					text_pattern: letter.trim(),
					color: color,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((response) => {
				if (response.status === 200) {
					console.log("[addTextColouring]added successfully", response);
					colouring = response.data.text_coloring;
					resolve(colouring);
				} else {
					reject(new Error(`[addTextColouring]Request failed with status ${response.status}`));
				}
			})
			.catch((error) => {
				console.error(`[addTextColouring]Error fetching the colorings`, error);
				reject(error);
			});
	});
}
function deleteTextColouring(user_id: string, text_coloring_id: string, token: string) {
	return new Promise((resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		let colouring: Highlight;
		backendApi
			.delete(`/users/${user_id}/text-colorings/${text_coloring_id}`, config)
			.then((response) => {
				if (response.status === 200) {
					console.log("[deleteConceptualMap]Deletion successful");
					colouring = response.data.message;
					resolve(colouring);
				} else {
					reject(new Error(`[deleteConceptualMap]Request failed with status ${response.status}`));
				}
			})
			.catch((error) => {
				console.error(`[deleteConceptualMap]Error deleting conceptual maps`, error);
				reject(error);
			});
	});
}

function getUserSettings(user_id: string, token: string) {
	return new Promise<UserSettings>((resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		let docSettings: UserSettings;
		backendApi
			.get(`/users/${user_id}/customizations`, config)
			.then((response) => {
				if (response.status === 200) {
					console.log("[getUserSettings]user view settings fetch successful", response);
					docSettings = response.data.user_customizations;
					resolve({
						font_name: docSettings.font_name ? docSettings.font_name : "open_dyslexic",
						line_height: docSettings.line_height ? docSettings.line_height : 5,
						font_size: docSettings.font_size ? docSettings.font_size : 30,
						speech_rate: docSettings.speech_rate,
						speech_gender: docSettings.speech_gender === "female" ? "F" : "M",
						theme: docSettings.theme,
					});
				} else {
					resolve({
						font_name: "arial",
						line_height: 50,
						font_size: 30,
					});
				}
			})
			.catch((error) => {
				console.error(`[getUserSettings]Error fetching user settings`, error);
				reject(error);
			});
	});
}

function setFontSize(user_id: string, fontSize: number, token: string) {
	return new Promise<UserSettings>((resolve, reject) => {
		let userSettings: UserSettings;
		backendApi
			.patch(
				`/users/${user_id}/customizations`,
				{
					font_size: fontSize,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((response) => {
				if (response.status === 200) {
					console.log("[setFontSize]update successful", response);
					userSettings = response.data.user_customizations;
					resolve(userSettings);
				} else {
					new Error(`[setFontSize]Request failed with status ${response.status}`);
				}
			})
			.catch((error) => {
				console.error(`[setFontSize]Error setting font size`, error);
				reject(error);
			});
	});
}
function setLineHeight(user_id: string, line_height: number, token: string) {
	return new Promise<UserSettings>((resolve, reject) => {
		let userSettings: UserSettings;
		backendApi
			.patch(
				`/users/${user_id}/customizations`,
				{
					line_height: line_height,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((response) => {
				if (response.status === 200) {
					console.log("[setLineHeight]update successful", response);
					userSettings = response.data.user_customizations;
					resolve(userSettings);
				} else {
					new Error(`[setLineHeight]Request failed with status ${response.status}`);
				}
			})
			.catch((error) => {
				console.error(`[setLineHeight]Error updating line height`, error);
				reject(error);
			});
	});
}
function setFontType(user_id: string, font: "arial" | "tahoma" | "serif" | "sans_serif" | "verdana" | "open_dyslexic", token: string) {
	return new Promise<UserSettings>((resolve, reject) => {
		let docFontSettings: UserSettings;
		backendApi
			.patch(
				`/users/${user_id}/customizations`,
				{
					font_name: font,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((response) => {
				if (response.status === 200) {
					console.log("[setfontType]set successfully", response);
					docFontSettings = response.data.user_customizations;
					resolve(docFontSettings);
				} else {
					new Error(`[setfontType]Request failed with status ${response.status}`);
				}
			})
			.catch((error) => {
				console.error(`[setfontType]Error setting font type`, error);
				reject(error);
			});
	});
}

function setTtsSpeed(user_id: string, tts_speed: "slow" | "medium" | "fast", token: string) {
	return new Promise<UserSettings>((resolve, reject) => {
		console.log(tts_speed);
		let ttsSpeed: number = 1;
		if (tts_speed === "slow") ttsSpeed = 0.5;
		else if (tts_speed === "medium") ttsSpeed = 1;
		else if (tts_speed === "fast") ttsSpeed = 1.5;
		else ttsSpeed;
		let docFontSettings: UserSettings;
		backendApi
			.patch(
				`/users/${user_id}/customizations`,
				{
					speech_rate: ttsSpeed,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((response) => {
				if (response.status === 200) {
					console.log("[setTtsSpeed]set successfully", response);
					docFontSettings = response.data.user_customizations;
					resolve(docFontSettings);
				} else {
					new Error(`[setTtsSpeed]Request failed with status ${response.status}`);
				}
			})
			.catch((error) => {
				console.error(`[setTtsSpeed]Error setting tts speed`, error);
				reject(error);
			});
	});
}
function setVoiceType(user_id: string, voiceType: "F" | "M", token: string) {
	return new Promise<UserSettings>((resolve, reject) => {
		console.log(voiceType);

		let docFontSettings: UserSettings;
		backendApi
			.patch(
				`/users/${user_id}/customizations`,
				{
					speech_gender: voiceType,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((response) => {
				if (response.status === 200) {
					console.log("[setVoiceType]set successfully", response);
					docFontSettings = response.data.user_customizations;
					resolve(docFontSettings);
				} else {
					new Error(`[setVoiceType]Request failed with status ${response.status}`);
				}
			})
			.catch((error) => {
				console.error(`[setVoiceType]Error setting voice type`, error);
				reject(error);
			});
	});
}

function setPreferredTheme(user_id: string, theme: string, token: string) {
	return new Promise<UserSettings>((resolve, reject) => {
		let docTheme: UserSettings;
		backendApi
			.patch(
				`/users/${user_id}/customizations`,
				{
					theme: theme,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((response) => {
				if (response.status === 200) {
					console.log("[setPreferredTheme]theme set successful");
					docTheme = response.data.user_customizations;
					resolve(docTheme);
				} else {
					resolve({
						theme: "default",
					});
				}
			})
			.catch((error) => {
				console.error(`[setPreferredTheme]Error setting theme`, error);
				reject(error);
			});
	});
}

function getPreferredTheme(user_id: string, token: string) {
	return new Promise<UserSettings>((resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		let docTheme: UserSettings;
		backendApi
			.get(`/users/${user_id}/customizations`, config)
			.then((response) => {
				if (response.status === 200) {
					console.log("[getPreferredTheme] successfully fetched preferred theme");
					docTheme = response.data.user_customizations;
					resolve(docTheme);
				} else {
					resolve({
						theme: "default",
					});
				}
			})
			.catch((error) => {
				console.error(`[getPreferredTheme]Error fetching preferred theme`, error);
				reject(error);
			});
	});
}

function getPrimaryLanguage(user_id: string, token: string) {
	return new Promise<string>((resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		let userLanguage: string;
		backendApi
			.get(`/users/${user_id}`, config)
			.then((response) => {
				if (response.status === 200) {
					console.log("[getPrimaryLanguage] successfully fetched user data");
					userLanguage = response.data.primary_language;
					resolve(userLanguage);
				} else {
					reject("[getPrimaryLanguage]Error fetching user data");
				}
			})
			.catch((error) => {
				console.error(`[getPrimaryLanguage]Error fetching user data`, error);
				reject(error);
			});
	});
}

function setPrimaryLanguage(user_id: string, token: string,language:string) {
	return new Promise<UserData>((resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		let userData: UserData;
		backendApi
			.patch(`/users/${user_id}`, 
			{
				primary_language: language,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((response) => {
				if (response.status === 200) {
					console.log("[getPrimaryLanguage] successfully fetched user data",response.data);
					userData = response.data;
					resolve(userData);
				} else {
					reject("[getPrimaryLanguage]Error fetching user data");
				}
			})
			.catch((error) => {
				console.error(`[getPrimaryLanguage]Error fetching user data`, error);
				reject(error);
			});
	});
}


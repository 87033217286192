import { combineReducers } from 'redux';
import { mapsConstant } from './constants';

export interface MapStore {
  disabledButtons: string[];
  fillNodeColors: boolean;
  showImagesCaptions: boolean;
  isUppercase: boolean;
}

const initialMapsState: MapStore = {
  disabledButtons: [],
  fillNodeColors: false,
  showImagesCaptions: true,
  isUppercase: false,
};

export interface NodePositionData {
  height: number,
  width: number,
  x: number,
  y: number,
}

export interface MapNode {
  id: string,
  label: string,
  source: string,
  target: string,
  type: string,
}
export interface MapEdge {
  id: string,
  label: string,
  color: string,
  position: NodePositionData,
  imageUrl: string,
}

export interface MapContent {
  nodes: MapNode[],
  edges: MapEdge[],
}

export interface MapData {
  id: string,
  created_by: string,
  title?: string,
  content: MapContent,
  created_at: string,
  study_session_id?: string
}
export interface ConceptualMap{
  id:string,
  created_by: string,
  title: string,
  content: {},
  created_at: number,
  study_session_id: string
}
// Reducer for handling disabled buttons
const disabledButtonsReducer = (state: string[] = [], action: any) => {
  switch (action.type) {
    case mapsConstant.DISABLE_MAP_BUTTON:
      return [...state, action.uuid];
    case mapsConstant.ENABLE_MAP_BUTTON:
      return state.filter((uuid) => uuid !== action.uuid);
    default:
      return state;
  }
};

// Reducer for toggling color fill
const toggleColorFillReducer = (state: boolean = true, action: any) => {
  if (action.type === mapsConstant.TOGGLE_COLOR_FILL) {
    return typeof action.payload === 'boolean' ? action.payload : !state;
  }
  return state;
};

// Reducer for toggling images captions
const toggleImagesCaptionsReducer = (state: boolean = true, action: any) => {
  if (action.type === mapsConstant.TOGGLE_IMAGES_CAPTIONS) {
    return typeof action.payload === 'boolean' ? action.payload : !state;
  }
  return state;
};

// Reducer for toggling uppercase
const toggleUppercaseReducer = (state: boolean = false, action: any) => {
  if (action.type === mapsConstant.TOGGLE_UPPERCASE) {
    return typeof action.payload === 'boolean' ? action.payload : !state;
  }
  return state;
};

// Maps reducer
const mapsReducer = combineReducers({
  disabledButtons: disabledButtonsReducer,
  fillNodeColors: toggleColorFillReducer,
  showImagesCaptions: toggleImagesCaptionsReducer,
  isUppercase: toggleUppercaseReducer,
  // Add more individual reducers if needed
});

export default mapsReducer;

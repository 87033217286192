import axios, { AxiosResponse } from "axios";
import { backendApi, backendApiDemo } from "../../../../common/apiService";
import { Audiobook } from "../../../audiobooks/store/reducers";
import { Summary } from "../../../summaries/store/reducers";
import { FlashcardsCollection } from "../../../flashcards/store/reducers";

export async function createFlashcardsCollection(token: string,language:string, session_id?: string, prompt?:string, title?:string) {
	try {
		const response: AxiosResponse = await backendApi.post(
			`/resources/flashcards-collections`,
			{
				study_session_id: session_id,
				prompt: prompt,
                title: title,
				language:language
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		if (response.status === 201) {
            console.log("[createFlashcardsCollection]Creation Successful:", response.status);
			return response.data;
		} else {
			console.error("[createFlashcardsCollection]Unexpected error:", response.status);
		}
	} catch (error) {
		// Handle errors
		if (axios.isAxiosError(error)) {
			console.error("[createFlashcardsCollection]Error creating flashcards collection:", error.response?.data || error.message);
		} else {
			console.error("[createFlashcardsCollection]Unexpected error:", error);
		}
	}
}

// export function getSingleFlashcardsCollection(flashcards_collection_id: string, token: string) {
// 	return new Promise<FlashcardsCollection>(async (resolve, reject) => {
// 		const config = {
// 			headers: {
// 				Authorization: `Bearer ${token}`,
// 			},
// 		};
// 		let flashcards_collections_obj: FlashcardsCollection;
// 		if (flashcards_collection_id) {
// 			backendApiDemo
// 				.get(`/resources/flashcards-collections/${flashcards_collection_id}`, config)
// 				.then((response) => {
// 					if (response.status === 200) {
//                         console.log("[getSingleFlashcardsCollection]Fetch Successful:", response.status);
// 						flashcards_collections_obj = response.data.summary;
// 						resolve(flashcards_collections_obj);
// 					} else {
// 						reject(new Error(`[getSummary]Request failed with status ${response.status}`));
// 					}
// 				})
// 				.catch((error) => {
// 					console.error(`[getSummary]Error fetching summary ${flashcards_collection_id}}`, error);
// 					reject(error);
// 				});
// 		}
// 	});
// }

// export function getFlashcardsCollections(token: string) {
// 	return new Promise<FlashcardsCollection[]>(async (resolve, reject) => {
// 		const config = {
// 			headers: {
// 				Authorization: `Bearer ${token}`,
// 			},
// 		};
// 		let flashcards_collections_obj: FlashcardsCollection[];
// 		backendApiDemo
// 			.get(`/resources/flashcards-collections`, config)
// 			.then((response) => {
// 				if (response.status === 200) {
//                     console.log("[getFlashcardsCollections]Fetch Successful:", response.status);

// 					flashcards_collections_obj = response.data.audiobook;
// 					resolve(flashcards_collections_obj);
// 				} else {
// 					reject(new Error(`[getSummaries]Request failed with status ${response.status}`));
// 				}
// 			})
// 			.catch((error) => {
// 				console.error(`[getSummaries]Error fetching summaries`, error);
// 				reject(error);
// 			});
// 	});
// }

export function deleteSingleFlashcardsCollection(flashcards_collection_id:string,token: string) {
	return new Promise<FlashcardsCollection[]>(async (resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		let flashcards_collections_obj: FlashcardsCollection[];
		backendApi
			.delete(`/resources/flashcards-collections/${flashcards_collection_id}`, config)
			.then((response) => {
				if (response.status === 200) {
                    console.log("[deleteSingleFlashcardsCollection]Deletion successful")
					flashcards_collections_obj = response.data.audiobook;
					resolve(flashcards_collections_obj);
				} else {
					reject(new Error(`[deleteSingleFlashcardsCollection]Request failed with status ${response.status}`));
				}
			})
			.catch((error) => {
				console.error(`[deleteSingleFlashcardsCollection]Error fetching summaries`, error);
				reject(error);
			});
	});
}

import { backendApi, backendApiDemo } from "../../../common/apiService";
import { ConceptualMap, MapData } from "./reducers";

export const mapsServices = {
    getSingleConceptualMap,
    updateSingleConceptualMap
};

export function updateSingleConceptualMap(map_id: string, token: string,study_session_id:string,content:{}) {
	return new Promise<ConceptualMap>(async (resolve, reject) => {
		let conceptual_map_obj: ConceptualMap;
		if (map_id) {
			backendApi
				.patch(`/resources/maps/${map_id}`, 
                    {   
                        content: content, // Map JSON here
                        study_session_id: study_session_id,
                    },{
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    }
                    
                )
				.then((response) => {
					if (response.status === 200) {
                        console.log("[updateSingleConceptualMap]update Successful:", response.status);
						conceptual_map_obj = response.data.content;
						resolve(conceptual_map_obj);
					} else {
						reject(new Error(`[updateSingleConceptualMap]Request failed with status ${response.status}`));
					}
				})
				.catch((error) => {
					console.error(`[updateSingleConceptualMap]Error updating  conceptual map: ${map_id}}`, error);
					reject(error);
				});
		}
	});
}

export function getMaps(token: string) {
	return new Promise<ConceptualMap[]>(async (resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		let conceptual_map_obj: ConceptualMap[];
		backendApi
			.get(`/resources/maps/`, config)
			.then((response) => {
				if (response.status === 200) {
                    console.log("[getMaps]Fetch Successful:", response.status);
					conceptual_map_obj = response.data.content;
					resolve(conceptual_map_obj);
				} else {
					reject(new Error(`[getMaps]Request failed with status ${response.status}`));
				}
			})
			.catch((error) => {
				console.error(`[getMaps]Error fetching conceptual maps`, error);
				reject(error);
			});
	});
}
export function getSingleConceptualMap(map_id: string, token: string) {
	return new Promise<ConceptualMap>(async (resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		let conceptual_map_obj: ConceptualMap;
		if (map_id) {
			backendApi
				.get(`/resources/maps/${map_id}`, config)
				.then((response) => {
					if (response.status === 200) {
                        console.log("[getSingleConceptualMap]Fetch Successful:", response.status);
						conceptual_map_obj = response.data;
						resolve(conceptual_map_obj);
					} else {
						reject(new Error(`[getSingleConceptualMap]Request failed with status ${response.status}`));
					}
				})
				.catch((error) => {
					console.error(`[getSingleConceptualMap]Error fetching single conceptual map: ${map_id}}`, error);
					reject(error);
				});
		}
	});
}

import { httpsCallable } from "@firebase/functions";
import { IonButton, IonIcon, IonInput, IonItem, IonLabel, IonText, isPlatform } from "@ionic/react";
import React from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import loading from '../../../assets/icons/loading.svg';
import firebaseConfig, { auth, database, functions } from "../../../firebaseConfig";
import { Product, fromProductsDbDataToProducts } from "../store/paywallUtils";
import "./Paywall.css";
import i18n from "../../../i18n";
// import { store } from '../../../common/storeHandler';
import { close, warning } from "ionicons/icons";
import { IAPProduct } from "@awesome-cordova-plugins/in-app-purchase-2";
import { onValue, ref } from "firebase/database";
import { loadPaywall, showOrHidePaywall } from "../../../loadPaywall";
import { sendEvent } from "../../amplitudeUtils";
import { appVersion } from "../../../appVersion";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { remoteConfig } from "../../../firebaseConfig";
import { User } from "firebase/auth";
import { paywallActions } from "../../../common/Paywall/store/actions";
import { store as paywallStateStore } from "../../../common/store";



type Props = {
    showPaywall: boolean,
    history: any,
};

type State = {
    isLicense: boolean,
    isInput: boolean,
    value: string | undefined,
    isWarning: boolean,
    isUsed: boolean,
    loadingLicense: boolean,
    isFirstPage: boolean,
    reasyMonthlyIapProduct: IAPProduct | null,
    trialIsUsed: boolean,
    remoteConfig: boolean,
    payWallState: boolean,
    userCreationDate: Date | null,
};

class Paywall extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            isLicense: false,
            isInput: false,
            value: undefined,
            isWarning: false,
            isUsed: false,
            loadingLicense: false,
            isFirstPage: false,
            reasyMonthlyIapProduct: null,
            trialIsUsed: false,
            remoteConfig: false,
            payWallState: true,
            userCreationDate: null,
        }
    }


    async componentDidMount() {

        if (isPlatform("capacitor") || isPlatform("cordova")) {
            // const reasyMonthlyIapProduct = store.get("ai.tech4all.reasy.full.monthly");
            // this.setState({ reasyMonthlyIapProduct: reasyMonthlyIapProduct });
        }
        fetchAndActivate(remoteConfig)
            .then((activated) => {
                if (activated) console.log("Fetched and activated configs!");
                else {
                    const remoteConfigValue = getValue(remoteConfig, "showLicenseCode").asBoolean();
                    this.setState({ remoteConfig: remoteConfigValue })
                }

            })
            .catch((err) => console.error("Failed to fetch and activate configs", err));
        // await loadPaywall(false).then(response => {
            // console.log("SLOWW STARTTT",response)

            let trialUsed: boolean = false
            let personalLicense: boolean = false
            auth.onAuthStateChanged(async (userData) => {
                if (userData !== null) {
                    if (userData.metadata.creationTime)
                        this.setState({ userCreationDate: new Date(userData.metadata.creationTime) })
                    else
                        // IF THERE'S NO CREATION DATE -> SET THE OLDEST DATE POSSIBLE -> SHOW THE PAYWALL
                        this.setState({ userCreationDate: new Date("1970-01-01Z00:00:00:000") })
                    const purchasesDbRef = ref(database, `u/${userData.uid}/p`);
                    onValue(
                        purchasesDbRef,
                        async (data) => {
                            if(data.val()){
                            // console.log("[Firebase] on purchases data red:", data.val())
                            const nowTimeStamp = Math.floor((new Date()).getTime() / 1000);
                            const purchasedProducts = fromProductsDbDataToProducts(data.val());
                            if (purchasedProducts.length != 0) {
                                purchasedProducts.forEach((element) => {
                                    if (element.expiryTimestamp < nowTimeStamp) {
                                        // console.log("SLOWW STARTTT",element.from)
                                        console.log("[IS USED]", element.from)
                                        if (element.from === "free")
                                            trialUsed = true
                                        personalLicense = true
                                    } else {
                                        personalLicense = false
                                        return
                                    }
                                })
                            }
                            if (trialUsed && personalLicense ) {
                                this.setState({ isUsed: true })
                            }
                            else {
                                this.setState({ isUsed: false })
                            }
                        }else{
                            this.setState({ isUsed: true })

                        }
                        })
                }
            })

        // })

    }


    render() {
        return (
            <>
                {(this.props.showPaywall && this.state.payWallState) && /* (this.state.userCreationDate && this.state.userCreationDate > new Date('2024-05-26')) && */ //NEW LICENSE MODEL DEPLOY DATE
                    <div className="paywallPayOuterDiv">
                        <div
                            className="paywallPayInnerDiv"
                            onClick={(e) => { e.stopPropagation() }}
                        >
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <IonIcon
                                    style={{ cursor: "pointer", color: 'var(--txt-color)' }}
                                    icon={close}
                                    onClick={() => {
                                        localStorage.setItem('isPayWallClose', JSON.stringify(true)); // Convert boolean to string
                                        paywallStateStore.dispatch(paywallActions.hidePaywall());
                                    }}
                                />
                            </div>

                            <div className="paywallPayContentDiv" hidden={true} >{/* HIDING THIS FOR THE NEW LICENSE MODEL, SO NO FIRST PAGE NEEDED, JUST REDEEM A CODE */}
                                <h2 hidden={this.state.isUsed}>
                                    <Trans>Partiamo!</Trans>
                                </h2>


                                <div className="paywallLicenseButtonDiv"  >
                                    <IonButton
                                        className="paywallLicenseButton"
                                        onClick={() => {
                                            this.setState({ isLicense: true, isFirstPage: true })
                                        }}
                                    >
                                        <Trans>Ho un codice licenza</Trans>
                                    </IonButton>

                                    {/*    <IonButton
                                        hidden={this.state.isUsed}
                                        className="paywallTrialButton"
                                        onClick={() => {
                                            this.setState({ loadingLicense: true, isFirstPage: true })
                                            const requestFreeTrial = httpsCallable(functions, "requestFreeTrial-requestFreeTrial");
                                            requestFreeTrial()
                                                .then(res => {
                                                    console.log(res)

                                                    sendEvent({
                                                        "user_id": auth.currentUser?.uid,
                                                        "event_type": "Free trial button pressed",
                                                        "language": i18n.language,
                                                        "app_version": appVersion,
                                                        "platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
                                                        "time": Date.now()
                                                    })
                                                    const data: any = res.data;
                                                    if (data.ok == "ok")
                                                        window.location.reload()
                                                })
                                                .catch(err => {
                                                    console.error("[Profile] error calling requestFreeTrial function:", err);
                                                })

                                        }}
                                    >
                                        {this.state.remoteConfig && <Trans>Non ho un codice licenza, </Trans>}
                                        <Trans>Provo gratis Reasy per 7 giorni</Trans>
                                    </IonButton> */}
                                    <IonLabel className="warning" hidden={!this.state.isWarning}><Trans></Trans></IonLabel>
                                    

                                </div>
                            </div>
                           
                                {/* 
                                <div className="paywallLicenseButtonDiv"  >
                                    
                                    <IonButton
                                        hidden={this.state.isUsed}
                                        className="paywallLicenseButton"
                                        onClick={() => {
                                            this.setState({ loadingLicense: true, isFirstPage: true ,isUsed:false})
                                            const requestFreeTrial = httpsCallable(functions, "requestFreeTrial-requestFreeTrial");
                                            requestFreeTrial()
                                                .then(res => {
                                                    const data: any = res.data;
                                                    if (data.ok === "ok")
                                                        window.location.reload()
                                                })
                                                .catch(err => {
                                                    console.error("[Profile] error calling requestFreeTrial function:", err);
                                                })
                                        }}
                                    >
                                        <Trans>Provo gratis Reasy per 7 giorni</Trans>
                                    </IonButton>

                                    {!isPlatform("android") && isPlatform("capacitor") && <IonButton
                                        className="paywallTrialButton"
                                        onClick={() => {
                                           window.location.replace("/purchase")
                                        }}
                                    >
                                        <Trans>Acquista</Trans>{" "}{this.state.reasyMonthlyIapProduct &&
                                                            this.state.reasyMonthlyIapProduct.price
                                                                ? this.state.reasyMonthlyIapProduct.price
                                                                : "€ 9,99"}{" "}
                                                            <Trans>al mese</Trans>
                                    </IonButton>
                                    }


                                    <IonLabel className="warning" hidden={!this.state.isWarning}><Trans></Trans></IonLabel>

                                </div>

                            */}
                                {
                                    (!isPlatform("android") && isPlatform("capacitor")) &&
                                    <>
                                        <div className="paywallContactUsButtonDiv">
                                            <a
                                                href="#"
                                                className="paywallReloadButton"
                                                onClick={() => {
                                                    // store.refresh()
                                                }}
                                            >
                                                <Trans>Aggiorna</Trans> {/* TO BE LOCALIZED */}
                                            </a>
                                        </div>
                                    </>
                                }
                            
                           {this.state.remoteConfig &&
                            <div /* hidden={!this.state.isLicense}  */ className="redeemAreaDiv">
                                <h2>
                                    <Trans>Inserisci il codice</Trans>
                                </h2>
                                <IonInput className="inputField" id="input"
                                    placeholder={
                                        i18n.language === "es"
                                            ? "Inserta aquí"
                                            : i18n.language === "en"
                                                ? "Insert here"
                                                : i18n.language === "fr"
                                                    ? "Insérez ici"
                                                    : i18n.language === "de"
                                                        ? "Hier einfügen"
                                                        : i18n.language === "ca" ?
                                                            "Insereix aquí"
                                                            : "Inserisci qui"
                                    }

                                    maxlength={30}
                                    onInput={() => {
                                        let inputFieldValue = (document.getElementById("input") as HTMLInputElement).value
                                        if (inputFieldValue.length > 4) {
                                            this.setState({ isInput: true, value: inputFieldValue })
                                        }
                                        else {
                                            this.setState({ isInput: false, value: undefined })
                                        }
                                    }}></IonInput>
                                <IonLabel className="warning" hidden={!this.state.isWarning}><Trans>Licenza non valida o incorretta</Trans>{/* TO BE LOCALIZED */} </IonLabel>
                                <IonButton className="paywallLicenseButton" disabled={!this.state.isInput} onClick={() => {
                                    this.setState({ loadingLicense: true })

                                    const redeemLicense = httpsCallable(functions, "redeemLicense-redeemLicense");
                                    redeemLicense({
                                        licenseCode: this.state.value
                                    })
                                        .then(res => {
                                            const data: any = res.data;
                                            sendEvent({
                                                "user_id": auth.currentUser?.uid,
                                                "event_type": "Redeem License",
                                                "event_properties": {
                                                    "license_code": this.state.value,
                                                    "is_valid": data.ok === 'ok' ? true : false
                                                },
                                                "language": i18n.language,
                                                "app_version": appVersion,
                                                "platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
                                                "time": Date.now()
                                            })

                                            if (data.ok == "ok")
                                                window.location.reload()
                                            if (data.error) {
                                                this.setState({ loadingLicense: false, isWarning: true, })
                                            };
                                        }
                                        )
                                        .catch(err => {
                                            console.error("[Profile] error calling redeemLicense function:", err);
                                        })
                                }}><Trans>Conferma</Trans></IonButton>


                                {/* <a className="paywallReloadButton"
                                    onClick={() => {
                                        this.setState({ isLicense: false, isFirstPage: false })
                                    }} ><Trans>Ritorna</Trans></a> */}
                            </div>
                            }


                            <div className="redeemAreaDiv" hidden={this.state.loadingLicense ? !this.state.loadingLicense : this.state.isUsed ? false : true}>
                                <IonIcon className='summaryLoadingIcon' icon={this.state.isUsed ? warning : loading}></IonIcon>
                                <div className='summaryPopoverOkTxt'>
                                    {!this.state.isUsed &&
                                        (<><IonText><Trans>Stiamo assegnando la tua prova!</Trans></IonText>
                                            <IonText><Trans>Appena sarà pronta la pagina si aggiornerà</Trans></IonText></>
                                        )}
                                    {this.state.isUsed && <IonText><Trans>La tua sottoscrizione è scaduta</Trans></IonText>}
                                </div>
                            </div>
                            <div className="paywallPayContentDiv">
                            {!isPlatform("android") && isPlatform("capacitor") && 
                            <IonButton
                                        className="paywallTrialButton"
                                        onClick={() => {
                                            window.location.replace("/purchase")
                                        }}
                                    >
                                        <Trans>Acquista</Trans>{" "}{this.state.reasyMonthlyIapProduct &&
                                            this.state.reasyMonthlyIapProduct.price
                                            ? this.state.reasyMonthlyIapProduct.price
                                            : "€ 6,99"}{" "}
                                        <Trans>al mese</Trans>
                                        {/* TO BE LOCALIZED */}
                                    </IonButton>
                                    } 
                            <IonItem className="contactSup" routerLink="/profile"><Trans>Contatta l'assistenza</Trans></IonItem>
                            </div>
                        </div>



                    </div>
                }
            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        showPaywall: state.paywall.showPaywall,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Paywall);
import { IonButton, IonCol, IonGrid, IonIcon, IonItem, IonList, IonRow, IonSelect, IonSelectOption } from '@ionic/react';
import { pauseCircleOutline, pauseOutline, playCircleOutline, playOutline, playSkipBackOutline, playSkipForwardOutline } from 'ionicons/icons';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Trans } from 'react-i18next';
import { auth } from '../../../firebaseConfig';
import { ttsServices } from '../../tts/store/services';
import "./ttsStyle.css";
import { settingsServices } from '../../settings/store/services';

type Props = {

    closeDictionary: () => void;
    dictionaryOpen: boolean,
    scrolled: any,
    showPlayer: boolean;
    audioToPlay?: string,
    disablePlayButton: boolean,
    disableTTSSkipButtons: boolean,
    disableOptions: boolean,

    isFirstParagraph: boolean,
    isLastParagraph: boolean,


    onPlayPause?: () => void,

    onForwardPressed?: () => void,
    onBackwardPressed?: () => void,
    onVoiceChange?: (arg0: string) => any,
    onRateChange: (arg0: string) => any,

    onPlaybackEnded?: () => void,
}
type State = {
    audioPlayer: HTMLAudioElement | undefined,
    isPlayingAudio: boolean,
    userUuid: string | null,
    skip: number;


}
class TtsPlayer extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            audioPlayer: undefined,
            isPlayingAudio: false,
            userUuid: null,
            skip: 0,
        }
    }
    componentDidMount() {
        if (isMobile) {
            document.getElementById("ttsbox")!.style.position = 'fixed';
        }
        const audioPlayer = new Audio('');
        audioPlayer.id = 'audioElement';
        document.body.append(audioPlayer);
        audioPlayer.onplay = () => {
            this.setState({ isPlayingAudio: true });
        };
        audioPlayer.onpause = () => {
            this.setState({ isPlayingAudio: false });
        };
        audioPlayer.onended = () => {
            this.setState({ isPlayingAudio: false });
            // for (let i = 0; i < document.getElementsByClassName('fileViewerParagraph').length; i++) {
            //     (document.getElementsByClassName('fileViewerParagraph')[i] as HTMLElement).classList.remove("paragraphinReading");
            // }
            if (this.props.onPlaybackEnded) {
                this.props.onPlaybackEnded();
            }
        };
        this.setState({ audioPlayer: audioPlayer });
        auth.onAuthStateChanged(userData => {
            if (userData) {
                userData.getIdToken().then(token=>{
                    settingsServices.getUserSettings(userData.uid,token).then(ttsSettings => {
                        if (ttsSettings.speech_rate === 0.5) {
                            (document.getElementById("rateselect") as HTMLSelectElement).value = 'slow';
                        } else if (ttsSettings.speech_rate === 1) {
                            (document.getElementById("rateselect") as HTMLSelectElement).value = 'medium';
                        } else if (ttsSettings.speech_rate === 1.5) {
                            (document.getElementById("rateselect") as HTMLSelectElement).value = 'fast';
                        }
    
                        if (ttsSettings.speech_gender === 'M') {
                            (document.getElementById("voiceselect") as HTMLSelectElement).value = 'M';
                        } else if (ttsSettings.speech_gender === 'F') {
                            (document.getElementById("voiceselect") as HTMLSelectElement).value = 'F';
    
                        }
                    })
                        .catch(err => {
                            console.log('[Settings] error getting TTS settings', err);
                        })
                })
               
            }
        })
    }

    changeSizeDictionary() {
        const selectionPopoverOuterDiv: HTMLDivElement = document.getElementById('selectionPopoverOuterDiv') as HTMLDivElement;
        const tts = document.getElementById("ttsbox")?.getBoundingClientRect();
        const ttsHeight = (tts?.height as any);
        let ttsTop = (tts?.top as any);
        if (this.props.scrolled > 0 && ttsTop < 0) {
            ttsTop = (ttsTop * -1)
        }
        if (this.props.showPlayer) {
            selectionPopoverOuterDiv.style.top = `${ttsHeight + ttsTop}px`;
            this.changePositionDictionary();
        }
    }

    changePositionDictionary() {
        const selectionPopoverDiv: HTMLDivElement = document.getElementById('selectionPopoverDiv') as HTMLDivElement;
        const tts = document.getElementById("ttsbox")?.getBoundingClientRect();
        const ttsLeft = (tts?.left as any);
        if (this.props.showPlayer) {
            selectionPopoverDiv.style.left = `${ttsLeft - 165}px`;
        }
        else {
            selectionPopoverDiv.style.left = `63%`;
        }
    }

    componentDidUpdate(prev: Props) {

        window.addEventListener('resize', () => {
            if (this.props.dictionaryOpen) {
                this.changePositionDictionary();
            }
        })

        if (this.props.dictionaryOpen) {
            this.changeSizeDictionary();
            document.getElementById('textgrid')!.classList.remove("spostaTesto");
        }
        else if (!this.props.dictionaryOpen && this.props.showPlayer && !isMobile) {
            document.getElementById('textgrid')!.classList.add("spostaTesto");
        }


        try {
            if (this.props.audioToPlay) {
                if ((prev.audioToPlay !== this.props.audioToPlay)) {
                    if (this.state.audioPlayer) {
                        this.state.audioPlayer.src = this.props.audioToPlay;
                        if ((prev.showPlayer === this.props.showPlayer) && this.props.showPlayer) {
                            this.state.audioPlayer.play();
                        }
                    }
                }
            }
            else {
                if (this.state.audioPlayer) {
                    this.state.audioPlayer.src = "";
                }
            }
        } catch (error) {
            console.log("ERRROR TTTS", error)
        }

        if (!this.props.showPlayer) {
            this.state.audioPlayer?.pause();
            this.state.audioPlayer!.currentTime = 0;
            this.state.audioPlayer!.src = "";
            if (prev.showPlayer !== this.props.showPlayer) {
                this.setState({ isPlayingAudio: false });
            }
        }
    }
    componentWillUnmount() {
        this.state.audioPlayer?.pause();
    }



    render() {
        return (
            <div hidden={!this.props.showPlayer} id='ttsbox' >
                <div style={{ width: '30px', backgroundColor: "var(--accents-color)", borderRadius: "10px 0px 0px 10px" }}></div>
                <div className='fileViewerAudioPlayer' style={{ borderRadius: "0px 10px 10px 0px" }} >
                    <p style={{ marginLeft: '15px' }}><b><Trans>Sintesi vocale</Trans></b></p>
                    <div className='fileViewerAudioPlayerButtonsDiv'>
                        <IonButton
                            disabled={this.props.disableTTSSkipButtons || this.props.isFirstParagraph}
                            className='ttsButtons'
                            fill="clear"
                            onClick={() => {
                                if (this.state.audioPlayer) {
                                    this.state.audioPlayer.pause();
                                }
                                if (this.props.onBackwardPressed) {
                                    this.props.onBackwardPressed();
                                }

                                if (this.props.closeDictionary) {
                                    this.props.closeDictionary();
                                }

                            }}
                        >
                            <IonIcon icon={playSkipBackOutline} />
                        </IonButton>
                        <IonButton
                            className='ttsButtons'
                            id='pauseplay'
                            fill="clear"
                            disabled={this.props.disablePlayButton}
                            onClick={() => {
                                if (this.state.audioPlayer) {
                                    if (this.state.isPlayingAudio) {
                                        this.state.audioPlayer.pause();
                                    }
                                    else {
                                        if (!this.state.audioPlayer.src.startsWith("data:audio/mpeg;base64")) {
                                            this.state.audioPlayer.src = this.props.audioToPlay ? this.props.audioToPlay : "";
                                        }
                                        this.state.audioPlayer.play();
                                    }
                                }
                                if (this.props.onPlayPause) {
                                    this.props.onPlayPause();
                                }
                            }}
                        >
                            <IonIcon icon={this.state.isPlayingAudio ? pauseCircleOutline : playCircleOutline} />
                        </IonButton>
                        <IonButton
                            disabled={this.props.disableTTSSkipButtons || this.props.isLastParagraph}
                            className='ttsButtons'
                            fill="clear"
                            onClick={() => {
                                if (this.state.audioPlayer) {
                                    this.state.audioPlayer.pause();
                                }
                                if (this.props.onForwardPressed) {
                                    this.props.onForwardPressed();
                                }
                                if (!isMobile && this.props.dictionaryOpen) {
                                    this.props.closeDictionary();
                                }
                            }}
                        >
                            <IonIcon icon={playSkipForwardOutline} />
                        </IonButton>
                    </div>
                    <IonList className='ttsControlls' lines='none' style={isMobile ? { display: 'inline-flex', flexDirection: 'column' } : {}}>
                        <IonItem className='ttsControllsItem' disabled={this.props.disableOptions}>
                            <div className='ttsLabels'>
                                <p>
                                    <b>
                                        <Trans>Velocita</Trans>
                                    </b>
                                </p>
                                <IonSelect
                                    id='rateselect'
                                    interface='action-sheet'
                                    onIonChange={e => {
                                        if (this.state.audioPlayer) {
                                            this.state.audioPlayer.pause();
                                        }
                                        this.props.onRateChange(e.detail.value);
                                    }}>
                                    <IonSelectOption value="slow"><Trans>Lento</Trans></IonSelectOption> {/* TO BE LOCALIZED */}
                                    <IonSelectOption value="medium"><Trans>Medio</Trans></IonSelectOption> {/* TO BE LOCALIZED */}
                                    <IonSelectOption value="fast"><Trans>Veloce</Trans></IonSelectOption> {/* TO BE LOCALIZED */}
                                </IonSelect>
                            </div>
                        </IonItem>
                        <IonItem className='ttsControllsItem' disabled={this.props.disableOptions}>
                            <div className='ttsLabels'>
                                <p>
                                    <b>
                                        <Trans>Gen</Trans>
                                    </b>
                                </p>
                                <IonSelect
                                    id='voiceselect'
                                    interface='action-sheet'
                                    onIonChange={e => {
                                        if (this.state.audioPlayer) {
                                            this.state.audioPlayer.pause();
                                        }
                                        if (this.props.onVoiceChange) {
                                            this.props.onVoiceChange(e.detail.value);
                                        }
                                    }}>
                                    <IonSelectOption value="M"><Trans>Voce 1</Trans></IonSelectOption>
                                    <IonSelectOption value="F"><Trans>Voce 2</Trans></IonSelectOption>
                                </IonSelect>
                            </div>
                        </IonItem>
                    </IonList>
                </div>
            </div>
        );
    }
}

export default TtsPlayer;
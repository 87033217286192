import { Device } from "@capacitor/device";
import i18n from "../../../i18n";
import { Argument } from "../store/reducers";
import { argumentServices } from "../store/services";
import { StatusBar } from '@capacitor/status-bar';

export function isImageValid(this: any, file: any) {
    return new Promise<boolean>((resolve, reject) => {
        let _URL = window.URL || window.webkitURL
        let _this = this
        let img = new Image();
        img.onload = function () {
            if (img.width > 8000 || img.height > 8000) {
                _this.setState({ isUploadingFile: false, isProcessingResources: false })
                _URL.revokeObjectURL(img.src);
                resolve(false)
            }
            else {
                _URL.revokeObjectURL(img.src);
                resolve(true)
            }
        };
        img.src = _URL.createObjectURL(file);
    })
}

export function uploadFile(this: any, file: any, userID: any, specificResource?: string, existingArgument?: Argument) {

    argumentServices.uploadFile
        .call(this, file, userID, this.state.bearerToken, undefined, specificResource, existingArgument)
        .then((response: any) => {
            console.log(response);
            this.setState({ generatedArgument: response, isUploadingFile: false, isProcessingResources: false }, () => {
                this.props.history.push({
                    pathname: "/session-details",
                    state: { argument: response },
                });
            });
        })
        .catch((err) => {
            console.error("[FilesManager] Error uploading file", err);
            let errorMessage =
                i18n.language === "it"
                    ? "Errore durante il caricamento del file. Foto/file non chiaro o dimensione superiore a 25MB, riprovare.(Se il file supera i 25MB, comprimi o separalo in più parti)"
                    : i18n.language === "en"
                        ? "Error during file upload. The photo/file is unclear or exceeds 25MB in size, please try again. (If the file exceeds 25MB, compress it or split it into multiple parts)"
                        : i18n.language === "fr"
                            ? "Erreur lors du chargement du fichier. La photo/le fichier est flou ou dépasse 25 Mo, veuillez réessayer. (Si le fichier dépasse 25 Mo, compressez-le ou divisez-le en plusieurs parties.)"
                            : i18n.language === "de"
                                ? "Fehler beim Hochladen der Datei. Das Foto/die Datei ist unklar oder überschreitet 25 MB, bitte versuchen Sie es erneut. (Wenn die Datei 25 MB überschreitet, komprimieren Sie sie oder teilen Sie sie in mehrere Teile auf.)"
                                : i18n.language === "ca"
                                    ? "Error durant la càrrega del fitxer. La foto/el fitxer no és clar o supera els 25 MB, si us plau, torni a intentar-ho. (Si el fitxer supera els 25 MB, comprimeixi'l o separi'l en diverses parts.)"
                                    : i18n.language === "es"
                                        ? "Error durante la carga del archivo. La foto/el archivo no está claro o supera los 25 MB, por favor, intente nuevamente. (Si el archivo supera los 25 MB, comprímalo o sepárelo en varias partes.)"
                                        : "Erro durante o carregamento do arquivo. A foto/arquivo está pouco claro ou excede 25 MB, por favor, tente novamente. (Se o arquivo exceder 25 MB, compacte-o ou divida-o em várias partes.)";
            alert(errorMessage);
            this.setState({ isUploadingFile: false, isProcessingResources: false })

            // this.setState({ isUploadingFile: false, showOcrPopover: false, disablePdfToMap: false });
        });
}

export function getStudySession(this: any, specificResource?: string, existingArgument?: Argument) {
    argumentServices.createArgumentFromString.call(this, this.state.newArgument, this.state.userUuid!, this.state.bearerToken, specificResource, existingArgument)
        .then((response: Argument) => {
 
                this.setState({ isProcessingResources: false, generatedArgument: response },()=>{
                    this.props.history.push({
                        pathname: '/session-details',
                        state: { argument: response }
                    })
                })
                
        })
}
export function clearSessionState() {
    localStorage.removeItem("sessionState");
    localStorage.removeItem("creationSessionState");
}

export const checkStatusBar = async () => {
    const info = await Device.getInfo();

    if (info.platform === 'ios') {
        const statusBarInfo = await StatusBar.getInfo();
        console.log('Status bar visible:', statusBarInfo.visible);

        // Status bar height (you can infer it, though it's often 20pt or 44pt in height)
        const statusBarHeight = statusBarInfo.visible ? (info.model.includes('iPhoneX') ? 44 : 20) : 0;
        console.log('Status bar height:', statusBarHeight);

        return statusBarHeight;
    } else {
        console.log('Not an iOS device');
        return 0;
    }
};
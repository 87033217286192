import axios, { AxiosResponse } from "axios";
import { FileData } from "../reducers";
import FormData from 'form-data';
import fs from 'fs';
import { createStudySession } from "./studySessions";
import { backendApi, backendApiDemo } from "../../../../common/apiService";

interface UploadResponse {
    filename: string;
    size: number;
}
// Update function to return a Promise
export async function s3UploadLinkRequest(
    file_size: number,
    file_name: string,
    file_type: string,
    token: string,
    user_id: string
): Promise<FileData | undefined> {
    try {
        const response = await backendApi.post(
            `/users/${user_id}/files`,
            {
                file_size,
                file_name,
                file_type
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );

        if (response.status === 200) {
            const fileData: FileData = response.data.file_data;
            console.log("[fileUploadLinkRequest] response:", response.data.message);
            console.log(fileData.presigned_url)
            return fileData;
        } else {
            console.error("[fileUploadLinkRequest] unexpected status code:", response.status);
            return undefined;
        }
    } catch (error) {
        // Handle different types of errors separately
        if (axios.isAxiosError(error) && error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            console.error("[fileUploadLinkRequest] response error:", error.response.data);
        } else if (axios.isAxiosError(error) && error.request) {
            // The request was made but no response was received
            console.error("[fileUploadLinkRequest] request error:", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error("[fileUploadLinkRequest] error:", error);
        }
        return undefined;
    }
}


export async function s3FileUpload(file:File | Blob,s3Link:string,file_id:string){
    console.warn(s3Link)
    const formData = new FormData();
    
    // Append the file or Blob to the FormData instance
    formData.append('file', file);

    try {
        // Perform the HTTP POST request to upload the file
        const response: AxiosResponse = await axios.put(s3Link, file,{
            headers:{
                'Content-Type':file.type
            }
        });
        return response.status===200
    } catch (error) {
        // Handle errors
        if (axios.isAxiosError(error)) {
            // TO DO: DELETE DOCUMENT IF ERROR HAPPENS USING THE DOCUMENT ID 
            console.error('Error uploading file:', error.response?.data || error.message);
        } else {
            console.error('Unexpected error:', error);
        }
        return false
    }
}


import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { FlashcardContent, Flashcards } from "../../sessions/store/reducers";
import { firestore } from "../../../firebaseConfig";
import { FlashcardsCollection } from "./reducers";
import { backendApi, backendApiDemo } from "../../../common/apiService";

export const flashcardsServices = {
    getFlashcardsCollections,
    getSingleFlashcardsCollection
};

export function getSingleFlashcardsCollection(flashcards_collection_id: string, token: string) {
    	return new Promise<FlashcardsCollection>(async (resolve, reject) => {
    		const config = {
    			headers: {
    				Authorization: `Bearer ${token}`,
    			},
    		};
    		let flashcards_collections_obj: FlashcardsCollection;
    		if (flashcards_collection_id) {
    			backendApi
    				.get(`/resources/flashcards-collections/${flashcards_collection_id}`, config)
    				.then((response) => {
    					if (response.status === 200) {
                            console.log("[getSingleFlashcardsCollection]Fetch Successful:", response.status);
    						flashcards_collections_obj = response.data.flashcards_collection;
    						resolve(flashcards_collections_obj);
    					} else {
    						reject(new Error(`[getSummary]Request failed with status ${response.status}`));
    					}
    				})
    				.catch((error) => {
    					console.error(`[getSummary]Error fetching summary ${flashcards_collection_id}}`, error);
    					reject(error);
    				});
    		}
    	});
    }
    
    export function getFlashcardsCollections(token: string) {
    	return new Promise<FlashcardsCollection[]>(async (resolve, reject) => {
    		const config = {
    			headers: {
    				Authorization: `Bearer ${token}`,
    			},
    		};
    		let flashcards_collections_obj: FlashcardsCollection[];
    		backendApi
    			.get(`/resources/flashcards-collections`, config)
    			.then((response) => {
    				if (response.status === 200) {
                        console.log("[getFlashcardsCollections]Fetch Successful:", response.status);
    
    					flashcards_collections_obj = response.data;
    					resolve(flashcards_collections_obj);
    				} else {
    					reject(new Error(`[getSummaries]Request failed with status ${response.status}`));
    				}
    			})
    			.catch((error) => {
    				console.error(`[getSummaries]Error fetching summaries`, error);
    				reject(error);
    			});
    	});
    }
import axios, { AxiosResponse } from "axios";
import { backendApi, backendApiDemo } from "../../../../common/apiService";
import { Audiobook } from "../../../audiobooks/store/reducers";
import { getUserData } from "../../../user/store/services";

export async function createAudiobook(token: string, session_id?: string, prompt?: string,language?:string) {
	try {
		const response: AxiosResponse = await backendApi.post(
			`/resources/audiobooks`,
			{
				study_session_id: session_id,
				language_code: language,
				prompt: prompt,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		if (response.status === 201) {
            console.log("[createAudiobook]Creation Successful:", response.status);
			return response.data;
		} else {
			console.error("[createSingleAudiobook]Unexpected error:", response.status);
		}
	} catch (error) {
		// Handle errors
		if (axios.isAxiosError(error)) {
			console.error("[createSingleAudiobook]Error creating audiobook:", error.response?.data || error.message);
		} else {
			console.error("[createSingleAudiobook]Unexpected error:", error);
		}
	}
}


export function deleteAudiobook(audiobook_id:string,token: string) {
	return new Promise<Audiobook>(async (resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		let audiobooks_obj: Audiobook;
		backendApi
			.delete(`/resources/audiobooks/${audiobook_id}`, config)
			.then((response) => {
				if (response.status === 200) {
                    console.log("[deleteConceptualMap]Deletion successful")
					audiobooks_obj = response.data.audiobook;
					resolve(audiobooks_obj);
				} else {
					reject(new Error(`[deleteConceptualMap]Request failed with status ${response.status}`));
				}
			})
			.catch((error) => {
				console.error(`[deleteConceptualMap]Error deleting conceptual maps`, error);
				reject(error);
			});
	});
}
import { backendApi, backendApiDemo } from "../../../common/apiService";
import { Audiobook } from "./reducers";

export const audiobookServices = {
    getSingleAudiobook,
    getAudiobooks
};



export function getSingleAudiobook(audiobookId: string, token: string) {
	return new Promise<Audiobook>(async (resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		let audiobookObj: Audiobook;
		if (audiobookId) {
			backendApi
				.get(`/resources/audiobooks/${audiobookId}`, config)
				.then((response) => {
					if (response.status === 200) {
                        console.log("[getSingleFlashcardsCollection]Fetch Successful:", response.status);
						audiobookObj = response.data;
						resolve(audiobookObj);
					} else {
						reject(new Error(`[getSingleAudiobook]Request failed with status ${response.status}`));
					}
				})
				.catch((error) => {
					console.error(`[getSingleAudiobook]Error fetching single audiobook: ${audiobookId}}`, error);
					reject(error);
				});
		}
	});
}

export function getAudiobooks(token: string) {
	return new Promise<Audiobook[]>(async (resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		let audiobookObj: Audiobook[];
		backendApi
			.get(`/resources/audiobooks/`, config)
			.then((response) => {
				if (response.status === 200) {
                    console.log("[getSingleFlashcardsCollection]Fetch Successful:", response.status);
					audiobookObj = response.data.audiobook;
					resolve(audiobookObj);
				} else {
					reject(new Error(`[getAudiobooks]Request failed with status ${response.status}`));
				}
			})
			.catch((error) => {
				console.error(`[getAudiobooks]Error fetching audiobooks`, error);
				reject(error);
			});
	});
}
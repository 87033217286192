import {
    IonButton,
    IonIcon,
    IonPage,
    IonRange,
    IonSelect,
    IonSelectOption,
    IonToolbar,
    isPlatform
} from "@ionic/react";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { arrowRedo, arrowUndo, chevronBackCircle, chevronBackCircleOutline, chevronForwardCircleOutline, pause, play, playBack, playForward, volumeMedium } from "ionicons/icons";
import React from "react";
import { connect } from "react-redux";
import { auth, remoteConfig } from "../../../firebaseConfig";
import { Argument } from "../../sessions/store/reducers";
import { Organization } from "../../user/store/reducers";
import "./Flashcards.css";
import { flashcardsServices } from "../store/services";
import { Flashcards } from "../../sessions/store/reducers";
import { isIOS, isMobile } from "react-device-detect";
import { checkStatusBar } from "../../sessions/utils/utils";
import { Trans } from "react-i18next";
import { FlashcardsCollection } from "../store/reducers";


type Props = {
    history: any;
    orgData: Organization | null

};

type State = {
    userUuid: string | null,
    userArguments: Argument[],
    // userArguments: any[],
    remoteConfig: boolean,
    newArgument: string,
    isProcessingResources: boolean,
    argument: Argument | null,
    flashcards: Flashcards | null
    isLoadingFlashcards: boolean,
    flashcardPointer: number,
    flashcardsCollection: FlashcardsCollection | null
};

class FlashcardsPage extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            userUuid: null,
            userArguments: [],
            remoteConfig: false,
            newArgument: '',
            isProcessingResources: false,
            argument: null,
            flashcards: null,
            isLoadingFlashcards: true,
            flashcardPointer: 0,
            flashcardsCollection: null,
        };
    }
    componentDidMount() {
        if (this.props.history.location.state.argument) {
            let argument = this.props.history.location.state.argument;
            this.setState({
                argument: argument,
            })
        }

        console.log(this.props.history.location.state.flashcardUuid);
        /*  flashcardsServices.getFlashcards(this.props.history.location.state.flashcardUuid)
             .then(flashcards => {
                 if (flashcards) {
                     this.setState({
                         flashcards: flashcards,
                     })
                 }
             }) */
        // (document.getElementById('audioRange') as HTMLIonRangeElement).max = this.audioElement.duration

        fetchAndActivate(remoteConfig)
            .then((activated) => {
                if (activated) console.log("Fetched and activated configs!");
                else {
                    const remoteConfigValue = getValue(remoteConfig, "showLicenseCode").asBoolean();
                    this.setState({ remoteConfig: remoteConfigValue })
                }

            })
            .catch((err) => console.error("Failed to fetch and activate configs", err));
        auth.onAuthStateChanged((userData) => {
            if (userData) {
                this.setState({ userUuid: userData.uid });
                userData.getIdToken().then(token => {
                    if (this.props.history.location.state.flashcardUuid) {
                        console.log("E DAII",this.props.history.location.state.flashcardUuid)
                        flashcardsServices.getSingleFlashcardsCollection(this.props.history.location.state.flashcardUuid, token)
                            .then(response => {
                                console.log(response)
                                this.setState({
                                    flashcardsCollection: response
                                })
                            })
                            .catch(err => {
                                console.error(err);
                            })

                    }
                })

            } else {
                this.setState({
                    userUuid: null,
                });
            }
        });
    }
    paletteColors = ["#C7DEFF", "#FFCCDC", "#BDBDFF", "#FCD9CA"]

    render() {
        return (
            <IonPage className="flashcardsPage body">
                {this.state.argument && this.state.flashcardsCollection && this.state.flashcardsCollection.flashcards && this.state.flashcardsCollection.flashcards.length > 0 &&
                    <div className="flashcardsMainDiv">
                        {(isMobile && (isPlatform('capacitor') && isIOS)) && <IonToolbar className="toolbarNotch" style={{ height: checkStatusBar() }}  ></IonToolbar>}

                        <IonButton
                            onClick={() => {
                                this.props.history.push({
                                    pathname: `/session-details`,
                                    state: { argument: this.state.argument }
                                });
                            }}
                            className="sessionsDetailsGoBackBtn"
                        >
                            <Trans>
                                Torna indietro
                            </Trans>
                        </IonButton>
                        <div className="sessionsHomeOnboardingTitle">
                            <p className="flashcardsTitle">"{this.state.argument?.title}"</p>
                        </div>
                        <div>
                            <p className="flashcardsCTAPar" style={{ margin: 0 }}>
                                <Trans>
                                    Clicca sulla carta per vedere la risposta
                                </Trans>
                            </p>
                        </div>
                        <div className="flashcardsContainer">
                            {this.state.flashcardsCollection.flashcards.map((fc, i) => {
                                return (
                                    <div
                                        className={i === 0 ? "fcCard card--current" : i === 1 ? "fcCard card--next" : "fcCard"}
                                        style={{ backgroundColor: this.paletteColors[i % this.paletteColors.length] }}
                                        onClick={(e) => {
                                            if (e.currentTarget.classList.contains('fcCardClicked'))
                                                e.currentTarget.classList.remove('fcCardClicked')
                                            else
                                                e.currentTarget.classList.add('fcCardClicked')

                                        }}
                                        key={i}
                                        id={`fc-${i}`}
                                    >
                                        <p className="fcCounter">{i + 1} <Trans>di</Trans> {this.state.flashcardsCollection!.flashcards.length}</p>
                                        <div className="fcCardInner">
                                            <div className="fcCardFront">
                                                <p>{fc.front_content}</p>
                                            </div>
                                            <div className="fcCardBack">
                                                <p>{fc.back_content}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            <IonIcon
                                className="fcButton fcPrevious"
                                icon={arrowUndo}
                                onClick={() => {
                                    let cards = document.querySelectorAll('.fcCard')
                                    let currentCard = document.getElementById(`fc-${this.state.flashcardPointer}`)
                                    if (this.state.flashcardsCollection && currentCard) {

                                        cards.forEach((card) => card.classList.remove('card--out', 'card--next', 'card--in', 'fcCardClicked'));
                                        // currentCard.classList.add('card--out');


                                        let nextIndex
                                        if (this.state.flashcardPointer === 0) {
                                            this.setState({ flashcardPointer: this.state.flashcardsCollection.flashcards.length - 1 })
                                            nextIndex = this.state.flashcardsCollection.flashcards.length - 1
                                        }
                                        else {
                                            this.setState({ flashcardPointer: this.state.flashcardPointer - 1 })
                                            nextIndex = this.state.flashcardPointer - 1
                                        }

                                        const nextCard = cards[nextIndex];

                                        nextCard.classList.add('card--in')
                                        setTimeout(() => {
                                            currentCard!.classList.remove('card--current',)
                                            nextCard.classList.remove('card--in');
                                            nextCard.classList.add('card--current');
                                        }, 600);

                                        let followingCard
                                        if (nextIndex - 1 > 0)
                                            followingCard = cards[nextIndex - 1];
                                        else
                                            followingCard = cards[cards.length - 1];
                                        followingCard?.classList.add('card--next');
                                    }

                                }}
                            >
                            </IonIcon>
                            <IonIcon
                                className="fcButton fcNext"
                                icon={arrowRedo}
                                onClick={() => {
                                    let cards = document.querySelectorAll('.fcCard')
                                    console.log("CARDS ARRAY: ", cards);
                                    let currentCard = document.getElementById(`fc-${this.state.flashcardPointer}`)
                                    if (this.state.flashcardsCollection && currentCard) {

                                        cards.forEach((card) => card.classList.remove('card--current', 'card--out', 'card--next', 'card--in', 'fcCardClicked'));
                                        currentCard.classList.add('card--out');

                                        let nextIndex
                                        if (this.state.flashcardPointer === this.state.flashcardsCollection.flashcards.length - 1) {
                                            this.setState({ flashcardPointer: 0 })
                                            nextIndex = 0
                                        }
                                        else {
                                            this.setState({ flashcardPointer: this.state.flashcardPointer + 1 })
                                            nextIndex = this.state.flashcardPointer + 1
                                        }
                                        const nextCard = cards[nextIndex];
                                        setTimeout(() => {
                                            currentCard!.classList.remove('card--out');

                                        }, 600);
                                        nextCard.classList.add('card--current');

                                        let followingCard
                                        if (nextIndex + 1 < cards.length)
                                            followingCard = cards[nextIndex + 1];
                                        else
                                            followingCard = cards[0];
                                        followingCard?.classList.add('card--next');
                                    }
                                }}
                            >
                                Prossima
                            </IonIcon>
                        </div>
                        <div className="bottomButtons">

                        </div>
                    </div>
                }
            </IonPage>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        orgData: state.user.organization,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlashcardsPage);

import { backendApi, backendApiDemo } from "../../../common/apiService";
import { Summary } from "./reducers";

export const summaryServices = {
    getSummary,
    getSummaries
};
/* NEW BACKEND */
export function getSummary(summary_id: string, token: string) {
	return new Promise<Summary>(async (resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		let summaryObj: Summary;
		if (summary_id) {
			backendApi
				.get(`/resources/summaries/${summary_id}`, config)
				.then((response) => {
					if (response.status === 200) {
                        console.log(response.data)
						summaryObj = response.data;
						resolve(summaryObj);
					} else {
						reject(new Error(`[getSummary]Request failed with status ${response.status}`));
					}
				})
				.catch((error) => {
					console.error(`[getSummary]Error fetching summary ${summary_id}}`, error);
					reject(error);
				});
		}
	});
}

export function getSummaries(token: string) {
	return new Promise<Summary[]>(async (resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		let summaryObj: Summary[];
		backendApi
			.get(`/resources/summaries/`, config)
			.then((response) => {
				if (response.status === 200) {
					summaryObj = response.data.audiobook;
					resolve(summaryObj);
				} else {
					reject(new Error(`[getSummaries]Request failed with status ${response.status}`));
				}
			})
			.catch((error) => {
				console.error(`[getSummaries]Error fetching summaries`, error);
				reject(error);
			});
	});
}
import axios from 'axios';
import { auth } from '../firebaseConfig';

// PRODUCTION ENVIRONMENT
// const apiService = axios.create({ baseURL: "https://api.reasy.estro.ai/rest/" });
// const refreshService = axios.create({ baseURL: "https://api.reasy.estro.ai/rest/" });
export const backendApiDemo = axios.create({ baseURL: "https://demo.api.reasyedu.com/Demo" })
export const backendApiProd = axios.create({ baseURL: "https://api.reasyedu.com/Prod" })

export const backendApi = backendApiProd

// TESTING ENVIRONMENT
const apiService = axios.create({ baseURL: "https://test.api.reasy.estro.ai/rest/" });


export const summarizationApi = axios.create({ baseURL: "https://api.meaningcloud.com/summarization-1.0" })


export const genericApiService = axios.create();


export default apiService;

import { SignInWithApple, SignInWithAppleOptions } from "@capacitor-community/apple-sign-in";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { ref, update } from "@firebase/database";
import {
	getDownloadURL,
	getStorage,
	ref as storageRef,
} from "@firebase/storage";
import {
	IonAccordion,
	IonAccordionGroup,
	IonBackButton,
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonGrid,
	IonHeader,
	IonIcon,
	IonImg,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonLoading,
	IonMenuButton,
	IonPage,
	IonPopover,
	IonRow,
	IonSelect,
	IonSelectOption,
	IonSpinner,
	IonText,
	IonTitle,
	IonToolbar,
	isPlatform,
} from "@ionic/react";
import { AuthProvider, EmailAuthProvider, GoogleAuthProvider, reauthenticateWithCredential, reauthenticateWithPopup, reauthenticateWithRedirect, signInWithCredential } from "firebase/auth";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import {
	call,
	chevronBack,
	chevronForwardOutline,
	globeOutline,
	lockOpenOutline,
	mail,
	openOutline,
	person,
	settingsOutline
} from "ionicons/icons";
import React from "react";
import { isMacOs, isMobile, isTablet } from "react-device-detect";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { appVersion } from "../../../appVersion";
import reasyLogo from '../../../assets/icons/reasyLogo.svg';
import { paywallActions } from "../../../common/Paywall/store/actions";
import { sendEvent } from "../../../common/amplitudeUtils";
import { store as paywallStateStore } from "../../../common/store";
import { appleAuthProvider, auth, database, firestore, functions, microsoftAuthProvider } from "../../../firebaseConfig";
import i18n from "../../../i18n";
import { setTheme } from "../../../theme/theme";
import { authActions } from "../../authentication/store/actions";
import { Document } from "../../files/store/reducers";
import { filesServices } from "../../files/store/services";
import { redeemBook } from "../../files/utils/utils";
import MenuTabBar from "../../menu/MenuTabBar";
import SideMenu from "../../menu/SideMenu";
import { userActions } from "../store/actions";
import { Device, Organization, UserData } from "../store/reducers";
import { userServices } from "../store/services";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { remoteConfig } from "../../../firebaseConfig";
import "./Profile.css";
import { httpsCallable } from "firebase/functions";
import { Product } from "../../../common/Paywall/store/paywallUtils";
import school from '../../../assets/icons/school.png'
import reasyAmbassadorIcon from '../../../assets/icons/reasy_ambassador_icon.svg'


type Props = {
	history: any;
	profile: any;
	orgData: Organization | null | 'Private';
	userDocuments: Document[] | null
	logout: () => void;
	setBannerLicenseRedeemed: (isRedeemed: boolean) => void;
};

type State = {
	userData: UserData | null;
	userUuid: string | null;
	userEmail: string | null;
	userOrganizationName: string | null;
	isMenuOpen: boolean;
	showDeleteAccountConfirm: boolean,

	userDevices: Device[];
	userPassword: string,
	showError: boolean,
	errorMessage: string,
	ISBNcode: string | null,
	showRedeemBookError: boolean,
	isLoadingRedeemBook: boolean,
	showConfirmationMessage: boolean,
	showBookAlreadyRedeemedError: boolean,
	documents: Document[] | null,
	userProviders: AuthProvider[],
	isLoading: boolean,
	remoteConfig: boolean,
	userCreationDate: Date | null,
	orgActiveProds: Product[],
	orgHasImage: boolean,
	isSearchingOrg: boolean,
};

class Profile extends React.Component<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			userData: null,
			userUuid: null,

			userEmail: null,

			isMenuOpen: false,
			userOrganizationName: null,
			showDeleteAccountConfirm: false,
			userDevices: [],
			userPassword: '',
			showError: false,
			errorMessage: '',
			ISBNcode: null,
			showRedeemBookError: false,
			isLoadingRedeemBook: false,
			showConfirmationMessage: false,
			showBookAlreadyRedeemedError: false,
			documents: null,
			userProviders: [],
			isLoading: false,
			remoteConfig: false,
			userCreationDate: null,
			orgActiveProds: [],
			orgHasImage: false,
			isSearchingOrg: false,
		};
	}

	componentDidMount() {
		fetchAndActivate(remoteConfig)
			.then((activated) => {
				if (activated) console.log("Fetched and activated configs!");
				else {
					const remoteConfigValue = getValue(remoteConfig, "showLicenseCode").asBoolean();
					this.setState({ remoteConfig: remoteConfigValue })
				}

			})
			.catch((err) => console.error("Failed to fetch and activate configs", err));
		auth.onAuthStateChanged((userData) => {
			if (userData) {
				userData.getIdToken().then(token => {
					if (userData.metadata.creationTime)
						this.setState({ userCreationDate: new Date(userData.metadata.creationTime) })
					this.setState({ userUuid: userData.uid });
					if (userData.email) {
						this.setState({ userEmail: userData.email });
					}
					userServices
						.fetchDevices(userData.uid)
						.then((devices) => {
							//console.log("[UserSettings] get devices response:", devices)
							this.setState({ userDevices: devices });
						})
						.catch((err) => {
							console.error("[Profile] get devices error:", err);
						});
					if (!this.props.userDocuments) {
						const q = query(collection(firestore, "f"), where("o", "==", userData.uid));
						const unsubscribe = onSnapshot(q, async (querySnapshot) => {
							// this.setState({ isLoadingFoldersAndFiles: true });
							this.setState(
								{
									documents: filesServices.fromFilesSnapshotToArrayOfDocuments(querySnapshot),
								},
							)
						})
					}
					/* GET USER INFO FROM NEW BACKEND */
					userServices.getUserData(userData.uid, token)
						.then(response => {
							this.setState({ userData: response })
						})
						.catch(err => {

						})
					this.setState({ isSearchingOrg: true })
					userServices
						.getUserOrganization(userData.uid)
						.then(async (org_data) => {
							if (org_data && org_data !== 'Private') {
								const storage = getStorage();
								// console.log("[Profile] user's organization name:", organizationName);
								this.setState({ userOrganizationName: org_data.name });
								const getOrganizationActiveProducts = httpsCallable(functions, "getOrganizationActiveProducts-getOrganizationActiveProducts");
								try {
									await getOrganizationActiveProducts({
										organizationUuid: org_data.uuid,
									}).then(async (response) => {
										const getOrganizationActiveProductsRes = response.data;
										// console.log("[Firebase] org active products:", getOrganizationActiveProductsRes);
										if ((getOrganizationActiveProductsRes as any).orgActiveProducts) {
											let orgActiveProducts = (getOrganizationActiveProductsRes as any).orgActiveProducts as Product[];
											orgActiveProducts.sort((a, b) => a.expiryTimestamp - b.expiryTimestamp);
											this.setState({ orgActiveProds: orgActiveProducts })
										}
									});
								} catch (err) {
									console.error("[Firebase] error calling getOrganizationActiveProducts cloud function:", err);
								}


								if (org_data.name != null) {
									const imageUrl = getDownloadURL(
										storageRef(storage, `/l/${org_data.name}.png`)
									);
									let imgSpace = document.getElementById("ogImg") as HTMLIonImgElement;
									imageUrl.then((result) => {
										let logoUrl = result;
										console.log("logo: ", logoUrl);

										if (imgSpace) {
											if (logoUrl) {
												imgSpace.src = `${logoUrl}`;
												// imgSpace.style.backgroundSize = "contain";
												this.setState({ orgHasImage: true })
											}
											else {
												imgSpace.src = school
											}
										}
									})
										.catch(err => {
											// console.error("Error getting image from storage:", err);
											imgSpace.src = school
										});
									const organizationSection = document.getElementById(
										"organizationSection"
									);
									if (organizationSection) {
										organizationSection.style.display = "block";
									}
									this.setState({ isSearchingOrg: false })

								}

							} else {
								this.setState({ isSearchingOrg: false })
								console.log("Nessuna organizzazione");
							}
						})
						.catch((err) => {
							this.setState({ isSearchingOrg: false })
							console.error(
								"[Profile] error getting user's organization name:",
								err
							);
						});


					let userProviderData = auth.currentUser?.providerData
					let userProviders: AuthProvider[] = []
					console.log(JSON.stringify(auth.currentUser?.providerData));

					if (userProviderData)
						userProviderData.forEach(provider => {
							switch (provider.providerId) {
								case "google.com":
									userProviders.push(new GoogleAuthProvider());
									break;
								case "microsoft.com":
									userProviders.push(microsoftAuthProvider);
									break;
								case "apple.com":
									userProviders.push(appleAuthProvider);
									break;
								case "password":
									userProviders.push(new EmailAuthProvider())
									break;
								default:
									break;
							}
						});

					this.setState({ userProviders: userProviders })
				})
			} else {
				this.setState({
					userUuid: null,
					userEmail: null,
				});
			}
		});
	}
	deleteAccountWithPassword() {
		this.setState({ isLoading: true })
		let cred = EmailAuthProvider.credential(auth.currentUser!.email!, this.state.userPassword)
		if (cred)
			reauthenticateWithCredential(auth.currentUser!, cred)
				.then(() => {
					userServices
						.deleteAccount()
						.then(() => {
							userActions.setBannerLicenseRedeemed(false)
							this.props.logout();
							this.setState({ isLoading: false })
							setTimeout(() => {
								this.props.history.replace(
									"/authentication/get-started"
								);
							}, 700);
						})
						.catch((err) => {
							this.setState({ isLoading: false })
							console.error(
								"[Profile] error deleting account:",
								err
							);
						});
				})
				.catch((error) => {
					this.setState({ isLoading: false })
					if (error.code === 'auth/wrong-password') {
						this.setState({
							showError: true,
							errorMessage: i18n.language === 'it' ? 'La password è sbagliata' :
								i18n.language === 'en' ? 'The password is incorrect' :
									i18n.language === 'es' ? 'La contraseña es incorrecta' :
										i18n.language === 'de' ? 'Das Passwort ist falsch' :
											i18n.language === 'fr' ? 'Le mot de passe est incorrect' :
												'La contrasenya és incorrecta'
						})
					}
					else if (error.code === 'auth/too-many-requests') {
						this.setState({
							showError: true,
							errorMessage:
								i18n.language === 'it' ? 'Troppe richieste, riprova più tardi' :
									i18n.language === 'en' ? 'Too many requests, please try again later' :
										i18n.language === 'es' ? 'Demasiadas solicitudes, inténtelo más tarde' :
											i18n.language === 'de' ? 'Zu viele Anfragen, bitte versuchen Sie es später noch einmal' :
												i18n.language === 'fr' ? 'Trop de demandes, veuillez réessayer plus tard' :
													'Hi ha massa sol·licituds, torneu-ho a provar més tard'
						})
					}
					else {
						console.error('An unexpected error occurred:', error.code);
					}
				});
		else {
			this.setState({
				isLoading: false,
				showError: true,
				errorMessage:
					i18n.language === 'it' ? "Errore durante la rimozione dell'account, contatta il supporto." :
						i18n.language === 'en' ? "Error during account removal, please contact support." :
							i18n.language === 'es' ? "Error durante la eliminación de la cuenta, póngase en contacto con el soporte." :
								i18n.language === 'de' ? "Fehler beim Löschen des Kontos, wenden Sie sich bitte an den Support." :
									i18n.language === 'fr' ? "Erreur lors de la suppression du compte, veuillez contacter le support." :
										i18n.language === 'ca' ? "S'ha produït un error en eliminar el compte, contacteu amb l'assistència." :
											i18n.language === 'pt' ? "Erro durante a remoção da conta, contactar o suporte." :
												i18n.language === 'cz' ? "Chyba při odstraňování účtu, kontaktujte podporu." :
													i18n.language === 'nl' ? "Fout bij verwijderen account, neem contact op met support." : ''
			})
		}
	}
	async deleteAccountWithProvider() {
		if (isMobile) {
			if (this.state.userProviders.length > 0)
				if (this.state.userProviders.filter(prov => prov.providerId === 'google.com').length > 0) {
					/* NOT WORKING, FIX IT */
					const googleUser = await GoogleAuth.signIn() as any;
					const credential = GoogleAuthProvider.credential(googleUser.authentication.idToken);
					signInWithCredential(auth, credential)
						.then(result => {
							this.setState({ isLoading: true })
							userServices
								.deleteAccount()
								.then(() => {
									this.props.setBannerLicenseRedeemed(false)
									this.setState({ isLoading: false })
									this.props.logout();
									setTimeout(() => {
										this.props.history.replace(
											"/authentication/get-started"
										);
									}, 700);
								})
								.catch((err) => {
									this.setState({ isLoading: false })
									console.error(
										"[Profile] error deleting account:",
										err
									);
								});
						})
				}
				else if (this.state.userProviders.includes(appleAuthProvider)) {
					let options: SignInWithAppleOptions = {
						clientId: 'ai.tech4all.reasy',
						redirectURI: 'localhost:8100/profile',
						scopes: 'email name',
						// state: '12345',
						// nonce: 'nonce',
					};
					SignInWithApple.authorize()
						.then((result) => {
							this.setState({ isLoading: true })
							console.log("SIGNED IN with apple: ", result);
							let credentials = appleAuthProvider.credential({ idToken: result.response.identityToken })
							signInWithCredential(auth, credentials)
								.then(response => {
									console.log("SIGNED IN WITH CREDENTIAL: ", response);
									if (response.user)
										userServices
											.deleteAccount()
											.then(() => {
												this.props.setBannerLicenseRedeemed(false)
												this.props.logout();
												this.setState({ isLoading: false })
												setTimeout(() => {
													this.props.history.replace(
														"/authentication/get-started"
													);
												}, 700);
											})
											.catch((err) => {
												this.setState({ isLoading: false })
												console.error(
													"[Profile] error deleting account:",
													err
												);
											});
								})
								.catch(err => {
									this.setState({ isLoading: false })
									console.error("[Profile	] apple error signin-up:", err);
								})
						})
						.catch((error) => {
							this.setState({ isLoading: false })
							console.log("ERROR SIGNING IN: ", error);
						});

				}
				else if (this.state.userProviders.includes(microsoftAuthProvider)) {
					/* MICROSOFT SIGN IN IS NOT SUPPORTED ON MOBILE ATM */
					this.setState({
						showError: true,
						isLoading: false,
						errorMessage:
							i18n.language === 'it' ? "Il tuo account è stato creato con Microsoft, per rimuoverlo, accedi da web al sito app.reasyedu.com" :
								i18n.language === 'en' ? 'Your account was created with Microsoft, to remove it, log in from the web at app.reasyedu.com' :
									i18n.language === 'es' ? 'Tu cuenta fue creada con Microsoft, para eliminarla, inicia sesión desde la web en app.reasyedu.com' :
										i18n.language === 'fr' ? 'Votre compte a été créé avec Microsoft, pour le supprimer, connectez-vous depuis le web à app.reasyedu.com' :
											i18n.language === 'de' ? 'Ihr Konto wurde mit Microsoft erstellt. Um es zu entfernen, loggen Sie sich über das Internet unter app.reasyedu.com ein.' :
												i18n.language === 'ca' ? "El vostre compte s'ha creat amb Microsoft, per eliminar-lo, inicieu sessió al lloc web app.reasyedu.com" :
													i18n.language === 'pt' ? 'A sua conta foi criada com a Microsoft, para a remover, inicie sessão a partir da Web em app.reasyedu.com' :
														i18n.language === 'cz' ? 'Váš účet byl vytvořen u společnosti Microsoft, chcete-li jej odstranit, přihlaste se z webu na adrese app.reasyedu.com.' :
															i18n.language === 'nl' ? 'Uw account is aangemaakt bij Microsoft, om deze te verwijderen, logt u in vanaf het web op app.reasyedu.com' : ''

					})
				}
			return
		}
		else
			reauthenticateWithPopup(auth.currentUser!, this.state.userProviders[0]) //web supports all providers, so pick the first one
				.then((result: any) => {
					this.setState({ isLoading: true })
					userServices
						.deleteAccount()
						.then(() => {
							this.props.setBannerLicenseRedeemed(false)
							this.setState({ isLoading: false })
							this.props.logout();
							setTimeout(() => {
								this.props.history.replace(
									"/authentication/get-started"
								);
							}, 700);
						})
						.catch((err) => {
							this.setState({ isLoading: false })
							console.error(
								"[Profile] error deleting account:",
								err
							);
						});
				})
				.catch(err => {
					if (err.code === 'auth/user-mismatch') {
						this.setState({
							showError: true,
							errorMessage:
								i18n.language === 'it' ? 'Stai tentando di rimuovere un account diverso da quello con cui sei attualmente connesso.' :
									i18n.language === 'en' ? 'You are trying to remove an account different from the one you are currently logged in with.' :
										i18n.language === 'es' ? 'Estás intentando eliminar una cuenta diferente a la que estás conectado actualmente.' :
											i18n.language === 'fr' ? 'Vous essayez de supprimer un compte différent de celui avec lequel vous êtes actuellement connecté.' :
												i18n.language === 'de' ? 'Sie versuchen, ein anderes Konto zu löschen als das, mit dem Sie gerade angemeldet sind.' :
													i18n.language === 'ca' ? 'Estàs intentant eliminar un compte diferent del que estàs connectat actualment. ' :
														i18n.language === 'pt' ? 'Você está tentando remover uma conta diferente daquela com a qual está conectado no momento.' :
															i18n.language === 'cz' ? 'Snažíte se odebrat účet, který se liší od toho, s nímž jste aktuálně přihlášeni.' :
																i18n.language === 'nl' ? 'Je probeert een account te verwijderen die anders is dan degene waarmee je momenteel bent aangemeld.' :
																	''
						})
					}
				})

		return
		/* let providerCred = GoogleAuthProvider.credential(result)
		console.log(providerCred);
		reauthenticateWithCredential(auth.currentUser!, providerCred)
			.then((res: any) => {
				console.log("RESULT:", res);

			}) */
	}

	render() {
		return (
			<IonPage className="body">
				{!isMobile &&
					(this.state.userCreationDate && this.state.userCreationDate < new Date("07/04/2024") ||
						(this.props.orgData !== null && this.props.orgData !== 'Private')) &&
					(
						<SideMenu
							userDocuments={null}
							userUuid={this.state.userUuid}
							orgData={this.props.orgData}
							history={this.props.history}
							sideMenuProfile={{
								profileIcon: person,
								color: "var(--callToAction-color)",
							}}
							sideMenuSetting={{ settingsIcon: undefined, color: undefined }}
							sideMenuHome={{ homeIcon: undefined, color: undefined }}
						/>
					)}
				{/* {!isMobile && (this.state.userCreationDate && this.state.userCreationDate < new Date("07/04/2024") || (this.props.orgData !== null && this.props.orgData !== 'Private')) && (
					<IonButtons
						className="sideMenuBtn"
						slot="start"
						onClick={() => {
							if (window.location.pathname === "/profile") {
								document.getElementById("profileItem")!.style.color =
									"var(--callToAction-color)";
								document.getElementById("profileItem")!.style.fontWeight =
									"600";
							}
						}}
					>
						<IonMenuButton className="sideMenuBtnBurger" menu="sideMenu" />
					</IonButtons>
				)} */}
				{/* && (this.props.orgData !== null && this.props.orgData === 'Private') */}
				{!isMobile && (this.state.userCreationDate && this.state.userCreationDate > new Date("07/04/2024") ) && (
					<IonButtons
						slot="start"
						onClick={() => {
							this.props.history.push('/sessions')
						}}
					>
						<IonIcon style={{ fontSize: '45px', cursor: 'pointer', position: 'absolute', left: '30px', top: '30px' }} icon={chevronBack} />
					</IonButtons>
				)}


				<IonHeader mode="ios" className={"settingsHeaderTablet"} hidden={!isMobile}>
					<IonToolbar hidden={!isMobile} className="homeHeaderToolbar">
						{(this.state.userCreationDate && this.state.userCreationDate > new Date("07/04/2024") && (this.props.orgData !== null && this.props.orgData === 'Private')) && (
							<IonButtons
								slot="start"
								onClick={() => {
									this.props.history.goBack()
								}}
							>
								<IonIcon style={{ fontSize: '25px' }} icon={chevronBack} />
							</IonButtons>
						)}
						<IonLabel hidden={isMobile} style={{ fontSize: "128%", paddingLeft: "1vw", fontFamily: "Nunito" }}>
							Configurazioni
						</IonLabel>
						<p className={isTablet ? "settingsHeaderTitleTablet" : "settingsHeaderTitle"}>
							<Trans>Profilo</Trans>
						</p>
					</IonToolbar>
				</IonHeader>


				<IonGrid class={"grid"} id="openSideMenu">
					<IonTitle hidden={isMobile} className="profileHeader">
						<Trans>Profilo</Trans>
					</IonTitle>
					{this.state.userEmail && (
						<IonCard className="cardBg">
							{/* <IonCardHeader>
								<IonCardTitle className="title">
									Account
								</IonCardTitle>
							</IonCardHeader> */}

							<IonCardContent>
								<div className="profileUserDataDiv">
									<div className="profileUserDataRow">
										<p>
											<b>
												<Trans>Email</Trans>
											</b>
										</p>
										<p>{this.state.userEmail}</p>
									</div>
								</div>


							</IonCardContent>
						</IonCard>

					)}
					{this.state.userData?.referral_code && /* REMOVE TRUE */
						<IonCard className="cardBg">
							<IonCardContent>
								<div className="profileUserDataDiv">
									<div className="profileUserDataRow">
										<p
											className="profileUserAmbassadorPar"
											onClick={() => {
												this.props.history.push('/profile/ambassador')
											}}
										>
											<IonIcon className="reasyAmbassadorIcon" icon={reasyAmbassadorIcon} />
											<b>
												<Trans>Reasy Ambassador</Trans>
											</b>
											<IonIcon className="redirectIcon" icon={openOutline} />
										</p>
									</div>
								</div>
							</IonCardContent>
						</IonCard>
					}
					{isMobile && this.state.remoteConfig &&
						(this.state.userCreationDate && this.state.userCreationDate < new Date("07/04/2024") && (this.props.orgData !== null && this.props.orgData !== 'Private')) &&

						<IonItem
							lines="none"
							id="redeemLicenseButton"
							button
							detail={false}
							onClick={() => {
								paywallStateStore.dispatch(paywallActions.showPaywall());
							}}
							className="profileRedeemLicenseItem"
						>

							<IonIcon
								style={{
									margin: "10px",
								}}
								className="lockIconLicense"
								icon={lockOpenOutline}
							/>
							<Trans>Hai un codice licenza?</Trans>
						</IonItem>
					}
					{this.state.isSearchingOrg &&
						<IonCard className="cardBg">
							<IonCardContent>
								<div className="profileUserDataRow">
									<p>Caricamento dati licenza...</p>
									<IonSpinner />
								</div>
							</IonCardContent>

						</IonCard>
					}
					<IonCard id="organizationSection" className="cardBg">
						<IonCardHeader>
							<IonCardTitle className="title">
								<Trans>Organizzazione</Trans>
							</IonCardTitle>
						</IonCardHeader>

						<IonCardContent>
							<div className="organizationImg">
								<IonImg id="ogImg" className={this.state.orgHasImage ? '' : 'invertDefaultImageDarkMode'}></IonImg>
							</div>
							<div className="profileUserDataDiv">
								<div className="profileUserDataRow">
									<p>{this.state.userOrganizationName}</p>
								</div>
								{this.state.orgActiveProds.length > 0 &&
									<div className="profileUserDataRow">
										<p>Licenza attivata fino a {new Date(this.state.orgActiveProds[0].expiryTimestamp * 1000).toLocaleDateString()}</p>
									</div>
								}
							</div>

							{isMobile && this.props.orgData && this.props.orgData !== 'Private' && this.props.orgData.type === 'publisher' &&
								<IonAccordionGroup>
									<IonAccordion value="redeemBook" className="mobileRedeemBookAccordion">
										<IonItem lines='none' slot="header" className="mobileRedeemBookItem">
											<IonLabel><Trans>Riscatta un libro</Trans></IonLabel>
										</IonItem>
										<div slot="content" className="mobileRedeemBookContentDiv">
											<IonInput
												id="redeemBookInput"
												className="mobileRedeemBookInput"
												label={
													i18n.language === 'it' ? "Codice ISBN" :
														i18n.language === 'en' ? "ISBN-Code" :
															i18n.language === 'es' ? "Código ISBN" :
																i18n.language === 'fr' ? "Code ISBN" :
																	i18n.language === 'de' ? "ISBN-Code" :
																		i18n.language === 'ca' ? "Codi ISBN" : ''
												}
												labelPlacement="floating"
												onIonChange={(e) => {
													if (e.detail.value)
														this.setState({ ISBNcode: e.detail.value })
												}}
												value={this.state.ISBNcode}
											/>
											{this.state.showRedeemBookError &&
												<IonLabel className="mobileRedeemBookErrorLabel"><Trans>Il codice non è valido</Trans></IonLabel>
											}
											{this.state.showBookAlreadyRedeemedError &&
												<IonLabel className="mobileRedeemBookErrorLabel"><Trans>Libro già riscattato</Trans></IonLabel>
											}
											{this.state.showConfirmationMessage &&
												<IonLabel className="mobileRedeemBookConfirmation"><Trans>Libro riscattato con successo</Trans></IonLabel>
											}
											<IonButton
												disabled={this.state.isLoadingRedeemBook}
												onClick={() => {
													this.setState({ isLoadingRedeemBook: true })
													let ISBNcode = (document.getElementById('redeemBookInput') as HTMLIonInputElement).value as any;
													let regex = new RegExp(/^(?=(?:[^0-9]*[0-9]){10}(?:(?:[^0-9]*[0-9]){3})?$)[\d-]+$/);
													if (ISBNcode && regex.test(ISBNcode)) {
														let userDocuments = this.props.userDocuments ? this.props.userDocuments : this.state.documents ? this.state.documents : null
														redeemBook(this, ISBNcode, userDocuments)
													}
													else {
														this.setState({ showRedeemBookError: true, isLoadingRedeemBook: false, showConfirmationMessage: false, showBookAlreadyRedeemedError: false })
													}
												}}
												className="mobileRedeemBookPopoverButton"
											>
												<Trans>
													Riscatta
												</Trans>
											</IonButton>
										</div>
									</IonAccordion>
								</IonAccordionGroup>
							}
						</IonCardContent>
					</IonCard>
					{this.state.showDeleteAccountConfirm &&
						<IonPopover mode="md" className='accountDeletionPopover' onDidDismiss={() => this.setState({ showDeleteAccountConfirm: false })} isOpen={true}>
							<div className="accountDeletionPopoverDiv">
								<IonIcon className='summaryReadyIcon' icon={reasyLogo}></IonIcon>
								<div className='accountDeletionPopoverText'>
									<IonText><Trans>Vuoi davvero eliminare il tuo account?</Trans></IonText> {/* TO LOCALIZE THIS */}
								</div>
								{this.state.userProviders.filter(prov => prov.providerId === 'password').length > 0 &&
									<>
										<IonLabel><Trans>Inserisci la tua password</Trans></IonLabel>
										<IonItem lines="none">
											<IonInput
												type="password"
												placeholder={
													i18n.language === 'it' ? 'La tua password' :
														i18n.language === 'en' ? 'Your password' :
															i18n.language === 'es' ? 'Tu contraseña' :
																i18n.language === 'fr' ? 'Votre mot de passe' :
																	i18n.language === 'de' ? 'Ihr Passwort' :
																		'La teva contrasenya'
												}
												onIonChange={(e) => {
													if (e.detail.value !== undefined && e.detail.value !== null)
														this.setState({ userPassword: e.detail.value })
												}} />
										</IonItem>
									</>
								}
								<IonLabel style={{ color: 'red' }} hidden={!this.state.showError}>{this.state.errorMessage}</IonLabel>
								<IonButton
									disabled={this.state.userProviders.filter(prov => prov.providerId === 'password').length > 0 && this.state.userPassword.length === 0}
									className='removeAccountBtn'
									onClick={() => {
										if (this.state.userProviders.filter(prov => prov.providerId === 'password').length > 0) {
											this.deleteAccountWithPassword();
										}
										else
											this.deleteAccountWithProvider();

									}}>
									<Trans>Prosegui</Trans>
								</IonButton>
								<IonButton className='removeAccountCancelBtn' onClick={() => {
									this.setState({ showDeleteAccountConfirm: false })
								}}>
									<Trans>Annulla</Trans>
								</IonButton>
							</div>
						</IonPopover>
					}

					{
						!isMobile && this.state.userUuid &&
						(this.state.userCreationDate && this.state.userCreationDate < new Date("07/04/2024") || (this.props.orgData !== null && this.props.orgData !== 'Private')) &&
						(
							<IonCard className="languageContainer cardBg">
								<IonCardContent className="languageContent">
									<IonList lines="none" style={{ background: "none" }}>
										<IonItem className="ionItems">
											<div className="languageDocumentViewingItemDiv">
												<IonIcon className="lnIcon" icon={globeOutline}></IonIcon>
												<IonSelect
													className="appLanguageSelect"
													value={i18n.language}
													interface="alert"
													interfaceOptions={
														{
															header: i18n.language === 'it' ? 'Seleziona una lingua' :
																i18n.language === 'en' ? 'Select a language' :
																	i18n.language === 'es' ? 'Seleccione un idioma' :
																		i18n.language === 'de' ? 'Wählen Sie eine Sprache' :
																			i18n.language === 'fr' ? 'Sélectionner une langue' :
																				'Seleccioneu un idioma'
														}
													}
													onIonChange={(e) => {
														console.log(i18n.language)
														if (e.detail.value != i18n.language) {
															i18n.changeLanguage(e.detail.value);
															if (this.state.userUuid) {
																update(ref(database, `u/${this.state.userUuid}`), {
																	l: e.detail.value,
																})
																	.then(() => {
																		console.log("eDEde")
																		window.location.reload();
																	})
																	.catch((err) => {
																		console.error(
																			"[Profile] error updating user language:",
																			err
																		);
																	});
															}
														}
													}}
												>
													<IonSelectOption value="it">Italiano</IonSelectOption>
													<IonSelectOption value="en">English</IonSelectOption>
													<IonSelectOption value="fr">Français</IonSelectOption>
													<IonSelectOption value="es">Español</IonSelectOption>
													<IonSelectOption value="de">Deutsch</IonSelectOption>
													<IonSelectOption value="ca">Català</IonSelectOption>
													<IonSelectOption value="pt">Português</IonSelectOption>
													<IonSelectOption value="nl">Nederlandse</IonSelectOption>
													<IonSelectOption value="cz">Czech</IonSelectOption>


												</IonSelect>

											</div>
										</IonItem>
									</IonList>
								</IonCardContent>
							</IonCard>
						)}
					<IonCard className="cardBg">
						<IonCardContent>
							<div className="profileUserDataDiv">
								<IonItem routerLink="/settings" className="profileSettingsItem" lines='none' id="settingsItem">
									<IonIcon
										id="settingsIcon"
										size="medium"
										style={{
											margin: "10px",
											color: "var(--txt-color)",
											cursor: "pointer"
										}}
										icon={settingsOutline}
									/>
									<IonText><Trans>Configurazioni</Trans></IonText>{/* TO BE LOCALIZED */}
								</IonItem>
							</div>
						</IonCardContent>
					</IonCard>
					<IonCard className="cardBg">
						<IonCardHeader>
							<IonCardTitle className="title">
								<Trans>Assistenza</Trans>
							</IonCardTitle>
						</IonCardHeader>

						<IonCardContent className="profileSupportGrid">
							<IonRow
								onClick={() => {
									sendEvent({
										"user_id": this.state.userUuid,
										"event_type": "Support pressed",
										"event_properties": {
											"user_org": this.props.orgData === 'Private' ? "Private User" : this.props.orgData !== null ? this.props.orgData.name : "Private User",
											"channel": "call"
										},
										"language": i18n.language,
										"platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
										"app_version": appVersion,
										"time": Date.now()
									})
									window.open("tel:+393661126799");
								}}
								className="profileSupportRow"
							>
								<IonIcon icon={call} className="profileSupportIcon" />
								<IonText className="profileSupportLabel">
									<Trans>Telefono</Trans>
								</IonText>
							</IonRow>

							<IonRow
								onClick={() => {
									sendEvent({
										"user_id": this.state.userUuid,
										"event_type": "Support pressed",
										"event_properties": {
											"user_org": this.props.orgData === 'Private' ? "Private User" : this.props.orgData !== null ? this.props.orgData.name : "Private User",
											"channel": "email"
										},
										"language": i18n.language,
										"platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
										"app_version": appVersion,
										"time": Date.now()
									})
									window.open("mailto:support@reasyedu.com");
								}}
								className="profileSupportRow"
							>
								<IonIcon icon={mail} className="profileSupportIcon" />
								<IonText className="profileSupportLabel">E-mail</IonText>
							</IonRow>

						</IonCardContent>
					</IonCard>

					<IonCard className="profileLogoutDiv cardBg ios">
						<div
							className="logoutBtn"
							onClick={async () => {
								(isMobile && isPlatform("capacitor")) && this.state.userProviders.filter(prov => prov.providerId === 'google.com') && await GoogleAuth.signOut();
								this.props.logout();
								setTheme("default")
								setTimeout(() => {
									this.props.history.replace(
										"/authentication/get-started"
									);
								}, 700);
							}}
						>
							<Trans>Disconnetti</Trans>
						</div>

						{/* {
							(isPlatform("capacitor") || isPlatform("cordova")) &&
							<>
								<div
									className="profilePurchaseAccountBtn"
									onClick={() => {
										this.props.history.push("/purchase");
									}}
								>
									<Trans>Acquista</Trans>
									<IonIcon
										className="btnIcon"
										slot="end"
										icon={chevronForwardOutline}
									></IonIcon>
								</div>
								<div
									className="profileManageAccountBtn"
									onClick={() => {
										store.manageSubscriptions()
										// window.open("https://apps.apple.com/account/subscriptions", "_blank");
									}}
								>
									<Trans>Gestisci abbonamento</Trans>
									<IonIcon
										className="btnIcon"
										slot="end"
										icon={chevronForwardOutline}
									></IonIcon>
								</div></>
						} */}

						{/* TO DO: INSERT AN ALERT */}
						<p
							onClick={() => {
								this.setState({ showDeleteAccountConfirm: true });
							}}
						>
							<Trans>Elimina account</Trans>
						</p>

					</IonCard>

					<IonCard className="footer cardBg">
						Versione {appVersion}
						<a href="/legal/privacy-policy">Privacy Policy</a>
						<a href="/legal/terms-conditions">
							<Trans>Termini e Condizioni</Trans>
						</a>
					</IonCard>
				</IonGrid>
				{
					isMobile &&
					(this.state.userCreationDate && this.state.userCreationDate < new Date("07/04/2024") || (this.props.orgData !== null && this.props.orgData !== 'Private')) &&
					(
						<MenuTabBar profile={person} settings={undefined} home={undefined} />
					)
				}
				<IonLoading
					isOpen={this.state.isLoading}
					message={
						i18n.language === 'it' ? 'Caricamento...' :
							i18n.language === 'en' ? 'Loading...' :
								i18n.language === 'fr' ? 'Chargement...' :
									i18n.language === 'es' ? 'Cargando...' :
										i18n.language === 'de' ? 'Laden...' :
											i18n.language === 'ca' ? 'Carregant...' :
												i18n.language === 'pt' ? 'Carregando...' :
													i18n.language === 'cz' ? 'Carregando...' :
														i18n.language === 'nl' ? 'Bezig met laden...' : ''

					}
				/>
			</IonPage >
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		orgData: state.user.organization,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		logout: () => {
			dispatch(authActions.logout());
		},
		setBannerLicenseRedeemed: (isRedeemed: boolean) => {
			dispatch(userActions.setBannerLicenseRedeemed(isRedeemed));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

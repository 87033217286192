
import {
    IonCol,
    IonContent,
    IonFab,
    IonFabButton,
    IonGrid,
    IonIcon,
    IonItemDivider,
    IonLoading,
    IonPage,
    IonPopover,
    IonRow,
    IonSpinner
} from "@ionic/react";
import { chevronBackOutline, copyOutline } from "ionicons/icons";
import React from "react";
import { connect } from "react-redux";
import { auth } from "../../../firebaseConfig";
import i18n from "../../../i18n";
import { authActions } from "../../authentication/store/actions";
import { ReferredUser, UserData } from "../store/reducers";
import { userServices } from "../store/services";
import "./AmbassadorDashboard.css";


type Props = {
    history: any;

};

type State = {
    userData: UserData | null,
    showCopiedLinkPopup: boolean,

    referredUsers: ReferredUser[],
    isLoadingUserData: boolean,
    isLoadingReferredUsers: boolean,


};

class Ad extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            userData: null,
            showCopiedLinkPopup: false,

            referredUsers: [],
            isLoadingUserData: false,
            isLoadingReferredUsers: false,
        };
    }

    componentDidMount() {
        this.setState({ isLoadingUserData: true, isLoadingReferredUsers: true })
        auth.onAuthStateChanged((userData) => {
            if (userData) {
                userData.getIdToken().then(token => {
                    userServices.getUserData(userData.uid, token)
                        .then(response => {
                            if (response.referral_code) {
                                this.setState({ userData: response, isLoadingUserData: false, })
                                userServices.getReferredUsers(response.id, token)
                                    .then(response => {
                                        this.setState({ referredUsers: response, isLoadingReferredUsers: false })
                                    })
                                    .catch(err => {
                                        this.setState({ isLoadingReferredUsers: false, })
                                    })
                            }
                            else {
                                alert(i18n.t("Account Ambassador non valido, verifica di essere accreditato o contatta il supporto.")) /* TO LOCALIZE THIS */
                                this.props.history.push('/profile');
                                this.setState({ isLoadingReferredUsers: false })
                            }
                        })
                        .catch(err => {
                            this.setState({ isLoadingUserData: false })
                            console.error(err);
                        })
                })
            } else {

            }
        });
    }

    copyInviteLink() {
        let inviteLink = document.getElementById('invite-link')?.textContent;
        if (inviteLink) {
            // inviteLink.setSelectionRange(0, 99999); // For mobile devices
            navigator.clipboard.writeText(inviteLink);
            this.setState({ showCopiedLinkPopup: true })
        }
    }


    render() {
        return (
            <IonPage className="body">
                <IonFab horizontal="start" vertical="top">
                    <IonFabButton color={'warning'} onClick={() => {
                        this.props.history.push('/profile')
                    }}>
                        <IonIcon icon={chevronBackOutline} />
                    </IonFabButton>
                </IonFab>
                {this.state.userData &&
                    <IonContent className="ambassadorContent">
                        <div className="ambassadorTitleDiv">
                            <p className="ambassadorTitlePar">
                                Benvenuto
                                <p id="ambassadorName">
                                    {this.state.userData.first_name ? this.state.userData.first_name : this.state.userData.email.split('@')[0]}
                                </p>
                            </p>
                        </div>
                        <div className="ambassadorInfoDiv">
                            <div>
                                <p className="ambassadorCodeHeader">Il tuo codice referral</p>
                                <p className="ambassadorReferralCodePar">{this.state.userData.referral_code}</p>
                            </div>
                            <div className="ambassadorLinkSection">
                                <p className="ambassadorLinkHeader">Link di invito</p>
                                {/* https://app.reasyedu.com/?referral_code=WNPV-VL3E */}
                                <div className="ambassadorLinkDiv" id="link-div">
                                    <p
                                        className="ambassadorLinkPar"
                                        id="invite-link"
                                        onClick={() => {
                                            this.copyInviteLink()
                                        }}
                                    >
                                        https://app.reasyedu.com/?referral_code={this.state.userData.referral_code}
                                    </p>
                                    <IonIcon
                                        id="link-copy-icon"
                                        className="ambassadorLinkCopyIcon"
                                        onClick={() => {
                                            this.copyInviteLink()
                                        }}
                                        icon={copyOutline}
                                    />
                                </div>
                                <p className="ambassadorSharePar">
                                    Condividi questo link per invitare utenti ad iscriversi a Reasy
                                </p>
                            </div>
                        </div>
                        <IonItemDivider className="ambassadorDivder"></IonItemDivider>
                        <IonGrid className="ambassadorTable">
                            <IonRow className="ambassadorTableHeaderRow">
                                <IonCol>
                                    Utente invitato
                                </IonCol>
                                <IonCol>
                                    Data
                                </IonCol>
                                <IonCol>
                                    Ha acquistato un abbonamento?
                                </IonCol>
                            </IonRow>
                            {this.state.isLoadingReferredUsers &&
                                <IonRow style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'space-around' }}>
                                    <IonSpinner />
                                </IonRow>
                            }
                            <div className="ambassadorTableContentDiv">
                                {this.state.referredUsers.map(referredUser => {
                                    return (
                                        <IonRow className="ambassadorTableRow">
                                            {/* DEBUG THIS CODE AND CHECK IF ITS WORKING*/}
                                            <IonCol className="ambassadorReferredUserName">
                                                {referredUser.first_name}
                                            </IonCol>
                                            <IonCol className="ambassadorReferredDate">
                                                {new Date(referredUser.referral_date).toLocaleDateString()}
                                            </IonCol>
                                            <IonCol>
                                                {/* TODO ADD A LOGIC TO CHECK IF THE REFERRED USER HAS PURCHASED A LICENSE */}
                                                ❌
                                            </IonCol>
                                        </IonRow>

                                    )
                                })}
                            </div>
                        </IonGrid>
                        <IonPopover
                            showBackdrop={false}
                            isOpen={this.state.showCopiedLinkPopup}
                            trigger="link-copy-icon"
                            side="right"
                            alignment="center"
                            mode="ios"
                            onDidDismiss={() => {
                                this.setState({ showCopiedLinkPopup: false })
                            }}
                            onDidPresent={(e) => {
                                setTimeout(() => {
                                    e.target.dismiss()
                                }, 500);
                            }}>
                            <IonContent class="ion-padding">Link copiato!</IonContent>

                        </IonPopover>
                    </IonContent>
                }
                <IonLoading isOpen={this.state.isLoadingUserData} message={i18n.t('Sto caricando i tuoi dati...')} />
            </IonPage >
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        logout: () => {
            dispatch(authActions.logout());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Ad);
